@import '../variables.less';

.investor main .body.get-details {
    max-width: 710px;
    margin: 0 auto;

    input {
        font-size: 13px;
        outline: none;
    }

    h3 {
        font-size: 17px;
        font-family: 'Poppins Bold';
    }

    .btn-link {
        color: @secondary1;
        padding: 0;
        width: auto;
        margin: 0;
        height: auto;
        border: none;
        font-size: 11px;
    }

    .basic-information {
        .email,
        .password {
            .change {
                float: right;
                margin-top: 6px;
            }
        }
    }

    .complete-registration {
        margin: 40px auto;
        width: 240px;
        display: block;
    }

    .form-group {
        display: inline-block;
        width: 50%;
    }

    @media only screen and (max-width: 991px) {
        .form-group {
            display: block;
            width: 100%;
        }
    }
}

@import '~/variables.less';

.investor main .body.income-trust-register {
    max-width: 790px;
    margin: 0 auto;

    .content-box {
        padding: 70px 100px 60px 100px;

        h3 {
            font-family: 'Poppins Bold', sans-serif;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            color: @primary1;
        }

        h3 + p:not(.intro) {
            margin-top: 24px;
        }

        h4 {
            font-family: 'Poppins Medium', sans-serif;
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            color: @primary1;
            margin-top: 16px;
        }

        .try,
        .skip {
            float: right;
            position: relative;
            top: -4px;
        }

        .try {
            @secondary-button();

            .hand {
                position: relative;
                top: -2px;
            }

            &:hover {
                .hand {
                    top: -4px;
                    transition: top 0.4s;
                }
            }
        }

        .skip {
            height: 35px;
            border-radius: 20px;
            border: none;
            padding: 0 17px;
            font-size: 11px;
            line-height: 35px;
        }

        .intro {
            color: @primary1;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 24px;
        }

        .continue,
        .previous {
            display: inline-block;
            margin-top: 24px;

            .spinner-grow {
                margin-right: 10px;
                position: relative;
                top: -2px;
            }

            .spinner-border {
                border-width: 2px;
            }
        }

        .previous {
            float: left;
        }

        .previous ~ .continue {
            margin-left: 20px;
        }
    }

    .form-cards {
        display: flex;
        margin: 32px -8px 24px;

        .form-card {
            background-color: #fafafa;
            border-radius: 15px;
            border: 2px solid #f3f3f4;
            color: @primary1;
            display: flex;
            flex-direction: column;
            font-size: 15px;
            margin: 8px;
            padding: 16px 24px;
            width: 50%;

            .form-check {
                label {
                    font-family: 'Poppins SemiBold', sans-serif;
                }

                .checkmark {
                    top: 9px;
                }
            }

            p {
                color: #212529;
                font-size: 12px;
                line-height: 16px;
                margin: 0 0 8px;
            }

            .card-description {
                color: #212529;
                font-size: 12px;
                line-height: 1.3em;
                margin: 0 0 8px;

                ul {
                    margin: 0 0 8px;
                    padding: 0 0 0 16px;
                }

                li {
                    padding: 0 0 0.25em;
                    position: relative;

                    &:last-child {
                        padding: 0;
                    }
                }
            }
        }

        .form-card-selected {
            border-color: @secondary1;
            transition: border-color 0.3s ease;
        }
    }

    .form-cards-vertical {
        flex-direction: column;

        .form-card {
            width: 100%;
        }
    }

    &.details {
        .content-box {
            .available-funds,
            .first-name,
            .date-of-birth,
            .suburb,
            .state,
            .id-proof-number,
            .company-tax-file-number,
            .trust-tax-file-number {
                clear: both;
                float: left;
                width: 48%;
            }

            .investor-type,
            .last-name,
            .tax-file-number,
            .postcode,
            .country,
            .driver-licence-card-number,
            .driver-licence-state,
            .id-proof-expiry-date,
            .australian-company-number,
            .australian-business-number {
                display: inline-block;
                width: 48%;
                margin-left: 4%;
            }

            .driver-licence-card-number + .id-proof-expiry-date {
                clear: both;
                float: left;
                margin-left: 0;
            }

            .country,
            .driver-licence-state {
                float: left;
            }

            h4.trust,
            h4.company,
            h4.individual-1,
            h4.individual-2,
            .id-proof-type,
            .street-address {
                clear: both;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .investor main .body.income-trust-register {
        .content-box {
            margin-top: 22px;
            padding: 40px 28px 50px 28px;

            .continue {
                display: block;
                margin: 26px auto 0 auto;
            }

            .previous {
                display: none;
            }
        }

        &.details {
            .content-box {
                .available-funds,
                .investor-type,
                .first-name,
                .last-name,
                .date-of-birth,
                .tax-file-number,
                .suburb,
                .postcode,
                .state,
                .country,
                .id-proof-number,
                .id-proof-expiry-date,
                .driver-licence-card-number,
                .company-tax-file-number,
                .australian-company-number,
                .trust-tax-file-number,
                .australian-business-number {
                    display: block;
                    width: auto;
                    margin-left: 0;
                    float: none;
                }
            }
        }
    }
}

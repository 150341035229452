@import '~/variables.less';

.investor main .body.investment-portfolio {
    max-width: 1190px;
    margin: 0 auto;

    .box {
        width: calc((100% - 60px) / 3);
        display: inline-block;
        margin: 28px 0 15px 30px;
        box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.15);
        border-radius: 15px;
        padding: 24px;

        &:first-of-type {
            margin-left: 0;
        }

        .label {
            text-align: center;
            font-family: 'Poppins SemiBold';
        }

        .value {
            text-align: center;
            font-family: 'Poppins Bold';
        }

        .primary {
            .label {
                font-size: 17px;
            }

            .value {
                font-size: 38px;
            }
        }

        .secondary {
            color: @primary2;
            margin-top: 12px;

            .label {
                font-size: 14px;
            }

            .value {
                font-size: 20px;
            }
        }

        @media only screen and (max-width: 991px) {
            display: block;
            width: 100%;
            margin-left: 0;
            margin-top: 16px;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    .search {
        width: 264px;
        display: inline-block;
        margin-top: 40px;

        @media only screen and (max-width: 991px) {
            width: 100%;
            display: block;
        }

        .magnify {
            color: @primary2;
            position: relative;
            left: 12px;
            top: 12px;
            width: 16px;
            height: 16px;
            margin-bottom: -16px;
            float: left;
        }

        .form-control {
            height: 40px;
            font-size: 13px;
            color: @primary2;
            padding-left: 35px;
            padding-right: 35px;
        }

        .clear {
            width: 40px;
            height: 40px;
            border-radius: 6px;
            background-color: transparent;
            border: 0;
            color: @primary2;
            float: right;
            margin-bottom: -40px;
            padding: 0;
            line-height: 40px;

            &:hover {
                color: @primary2;
                box-shadow: none;
            }
        }
    }

    .status-filter {
        margin-top: 40px;
        width: 220px;
        float: right;

        @media only screen and (max-width: 991px) {
            width: 100%;
            float: none;
            margin-top: 20px;
        }

        .form-control {
            height: 40px;
            font-size: 13px;
            color: @primary2;
            font-family: 'Poppins Medium';
        }
    }

    .table.my-loans {
        font-size: 12px;
        margin-top: 2px;
        width: calc(100% + 80px);
        position: relative;
        left: -40px;
        border-left: 0;
        border-right: 0;
        color: @primary1;

        @media only screen and (max-width: 991px) {
            left: -15px;
            width: calc(100% + 30px);
            border-top: 0;
            border-bottom: 0;
        }

        th {
            background-color: #fafafa;
            color: @primary2;
            font-weight: 400;
            border-bottom-width: 1px;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 11px;

            @media only screen and (max-width: 991px) {
                display: none;
            }

            svg {
                margin-left: 6px;
            }
        }

        th,
        td {
            border-left: 0;
            border-right: 0;
            padding: 16px;

            @media only screen and (max-width: 991px) {
                border-top: 0;
                border-bottom: 0;
            }

            &:first-child {
                padding-left: 40px;
            }

            &:last-child {
                padding-right: 40px;
            }
        }

        @media only screen and (max-width: 991px) {
            tr {
                display: block;
            }

            td {
                display: block;
                padding: 0;
            }

            td.date {
                width: 100%;
                padding: 6px 15px;
                background-color: #fafafa;
                text-transform: uppercase;
                font-family: 'Poppins Medium';
            }

            td.loan-number {
                margin-top: 15px;
                margin-left: 15px;
                height: 18px;
                width: 40%;
            }

            td.status {
                font-size: 11px;
                margin-left: 15px;
                margin-top: 2px;
                height: 16px;
                display: inline-block;
                font-family: 'Poppins Medium';
                margin-bottom: 15px;
            }

            td.invested {
                float: right;
                font-size: 14px;
                font-family: 'Poppins Medium';
                height: 21px;
                margin-right: 15px;
                position: relative;
                top: -20px;
                text-align: right;
                width: 40%;
            }

            td.rate {
                display: inline-block;
                margin-left: 6px;
                font-size: 11px;
                height: 16px;
                margin-top: 2px;
            }

            td.expiration {
                float: right;
                padding-right: 0;
                margin-left: auto;
                height: 16px;
                margin-right: 15px;
                font-size: 11px;
                position: relative;
                top: -31px;
            }

            td.interest-earned {
                display: none;
            }
        }
    }

    .pagination {
        display: inline-block;
        width: fit-content;
        margin-top: 6px;

        .page-item {
            display: inline-block;

            .page-link {
                border: 0;
                width: 26px;
                height: 26px;
                text-align: center;
                font-size: 14px;
                line-height: 26px;
                padding: 0;
                color: @primary2;
                font-family: 'Poppins SemiBold';
                margin-left: 4px;
                border-radius: 6px;
            }

            &:first-child {
                .page-link {
                    margin-left: 0;
                }
            }

            &.active {
                .page-link {
                    background-color: @primary1;
                    color: #fff;
                }
            }
        }
    }

    .per-page {
        width: 80px;
        height: 32px;
        display: inline-block;
        margin-left: 30px;
        font-size: 14px;
        font-family: 'Poppins SemiBold';
        color: @primary2;
        padding: 0 12px;
        line-height: 32px;

        &:first-child {
            margin-left: 0;
        }
    }
}

@import '~/variables.less';

.investor main .body.get-statement {
    max-width: 1190px;
    margin: 0 auto;

    .form-group {
        width: calc((100% - 228px) / 3);
        display: inline-block;

        &.start-date,
        &.end-date {
            width: 180px;
        }

        &.type {
            width: 283px;
        }

        &.end-date,
        &.type {
            margin-left: 24px;
        }

        .form-control {
            height: 40px;
            font-size: 13px;
        }

        @media only screen and (max-width: 991px) {
            &.start-date,
            &.end-date {
                width: calc((100% - 15px) / 2);
                display: inline-block;
            }

            &.end-date {
                margin-left: 15px;
            }

            &.type {
                width: 100%;
                margin-left: 0;

                label {
                    display: none;
                }
            }
        }
    }

    .reset {
        width: 156px;
        height: 40px;
        background-color: #f3f3f4;
        color: @primary1;
        border: 0;
        font-family: 'Poppins SemiBold';
        margin-left: 24px;

        &:not(:disabled):not(.disabled):hover {
            color: @primary1;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: #f3f3f4;
        }

        @media only screen and (max-width: 991px) {
            margin-left: 0;
        }
    }

    .table.statements {
        font-size: 12px;
        margin-top: 30px;
        width: calc(100% + 80px);
        position: relative;
        left: -40px;
        border-left: 0;
        border-right: 0;
        color: @primary1;

        @media only screen and (max-width: 991px) {
            left: -15px;
            width: calc(100% + 30px);
            border-top: 0;
            border-bottom: 0;
        }

        th {
            background-color: #fafafa;
            color: @primary2;
            font-weight: 400;
            border-bottom-width: 1px;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 11px;

            @media only screen and (max-width: 991px) {
                display: none;
            }

            svg {
                margin-left: 6px;
            }
        }

        th,
        td {
            border-left: 0;
            border-right: 0;
            padding: 16px;

            @media only screen and (max-width: 991px) {
                border-top: 0;
                border-bottom: 0;
            }

            &:first-child {
                padding-left: 40px;
            }

            &:last-child {
                padding-right: 40px;
            }
        }

        td.description {
            white-space: pre-line;
        }

        @media only screen and (max-width: 991px) {
            tr {
                display: block;
                clear: both;
            }

            td {
                display: block;
                padding: 0;
            }

            td.date {
                width: 100%;
                padding: 6px 15px;
                background-color: #fafafa;
                text-transform: uppercase;
                font-family: 'Poppins Medium';
            }

            td.description {
                margin-top: 15px;
                margin-left: 15px;
                height: 18px;
                width: 40%;
            }

            td.amount {
                float: right;
                font-size: 14px;
                font-family: 'Poppins Medium';
                height: 21px;
                margin-right: 15px;
                position: relative;
                top: -20px;
                text-align: right;
                width: 40%;
                margin-bottom: -21px;
            }

            td.balance {
                float: right;
                padding-right: 0;
                margin-left: auto;
                height: 16px;
                margin-right: 15px;
                font-size: 11px;
                position: relative;
            }
        }
    }

    .pagination-block {
        width: 50%;
        display: inline-block;
        margin-top: 10px;
        clear: both;

        @media only screen and (max-width: 991px) {
            width: 100%;
        }

        .pagination {
            display: inline-block;
            width: fit-content;
            margin-bottom: 0;

            .page-item {
                display: inline-block;

                .page-link {
                    border: 0;
                    width: 26px;
                    height: 26px;
                    text-align: center;
                    font-size: 14px;
                    line-height: 26px;
                    padding: 0;
                    color: @primary2;
                    font-family: 'Poppins SemiBold';
                    margin-left: 4px;
                    border-radius: 6px;
                }

                &:first-child {
                    .page-link {
                        margin-left: 0;
                    }
                }

                &.active {
                    .page-link {
                        background-color: @primary1;
                        color: #fff;
                    }
                }
            }
        }

        .per-page {
            width: 80px;
            height: 32px;
            display: inline-block;
            margin-left: 30px;
            font-size: 14px;
            font-family: 'Poppins SemiBold';
            color: @primary2;
            padding: 0 12px;
            line-height: 32px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .export {
        float: right;
        margin-top: 6px;
        height: 40px;
        padding: 0;
        line-height: 36px;
        width: 156px;

        @media only screen and (max-width: 991px) {
            display: block;
            float: none;
            margin-left: auto;
            margin-top: 24px;
        }
    }
}

.broker {
    .performance-snapshot {
        .boxes {
            margin: 0 -15px;
            display: flex;
            padding-bottom: 24px;

            @media only screen and (max-width: 991px) {
                display: block;
                margin: 0;
            }

            .box {
                max-width: 320px;
                min-width: 30%;
                margin: 28px 15px 15px 15px;
                box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.15);
                border-radius: 15px;
                padding: 20px;
                flex: 1;
                word-wrap: break-word;

                .label {
                    text-align: center;
                    font-family: 'Poppins SemiBold';
                }

                .value {
                    text-align: center;
                    font-family: 'Poppins Bold';
                }

                .primary {
                    .label {
                        font-size: 20px;
                    }

                    .value {
                        font-size: 28px;
                    }
                }

                @media only screen and (max-width: 991px) {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    margin: 16px 0 0 0;
                    flex: 0;

                    &:first-of-type {
                        margin-top: 28px;
                    }
                }
            }
        }
    }
}
@import '../variables.less';

.broker main .body.dashboard {
    max-width: 1190px;
    margin: 0 auto;

    .main-box.quotes {
        margin-top: 20px;
    }

    .broker-actions {
        .action-boxes {
            display: flex;

            @media only screen and (max-width: 768px) {
                flex-direction: column;
            }

            .action-box {
                background-color: #fff;
                flex-direction: row;
                flex: 1 1 50%;
                margin: 28px 15px 15px 15px;
                box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.15);
                border-radius: 8px;
                padding: 24px;

                svg {
                    width: 100%;
                    margin: 0 auto;
                }

                .primary {
                    margin-top: 10px;

                    .label {
                        font-size: 17px;
                        text-align: center;
                        font-family: 'Poppins SemiBold';
                    }

                    .detail {
                        text-align: center;
                        font-size: 15px;
                        min-height: 100px;
                        margin-top: 10px;
                    }
                }

                .action-button {
                    display: block;
                    text-align: center;
                }

                &:first-of-type {
                    margin-left: 0px;
                }

                &:last-of-type {
                    margin-right: 0px;
                }

                @media only screen and (max-width: 991px) {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    margin: 16px 0 0 0;
                    flex: 0;

                    &:first-of-type {
                        margin-top: 28px;
                    }
                }
            }
        }
    }
}

@import '../public.less';

.broker {
    min-height: 500px;

    .email-verification,
    .require-approval {
        max-width: 700px;
        background-color: #fff;
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        border-radius: 6px;
        padding: 70px 100px 60px 100px;
        font-weight: 400;
        margin-top: 76px;

        h3 {
            font-family: 'Poppins Bold';
            text-align: center;
            margin-bottom: 30px;
        }

        .intro {
            color: @primary1;
            font-family: 'Poppins Regular';
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }

    .login {
        max-width: 600px;
        background-color: #fff;
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        border-radius: 6px;
        padding: 70px 100px 60px 100px;
        font-weight: 400;
        margin-top: 76px;

        @media only screen and (max-width: 768px) {
            padding: 30px;
        }

        .forgot-password {
            font-size: 12px;
            text-align: right;
            float: right;
            margin-bottom: 16px;
        }

        h3 {
            font-size: 40px;
            text-align: center;
            margin-bottom: 30px;
        }

        .input-group-prepend {
            padding: 15px;
            border: 1px solid #c1c5cf;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        .continue {
            min-width: 100%;
        }
    }

    .register {
        max-width: 700px;
        background-color: #fff;
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        border-radius: 6px;
        padding: 70px 100px 60px 100px;
        font-weight: 400;
        margin-top: 76px;

        h4 {
            margin-top: 16px;
        }

        .intro {
            color: @primary1;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 24px;
        }

        .first-name,
        .phone,
        .state {
            clear: both;
            float: left;
            width: 240px;
        }

        .last-name,
        .email,
        .postcode {
            float: left;
            width: 240px;
            margin-left: 20px;
        }

        .company-name {
            clear: both;
        }

        .file-upload-wrapper {
            width: 240px;
            background-color: @neutral5;
            border-radius: 50px;
            height: 45px;
            position: relative;
            cursor: pointer;
            font-size: 12px;
            line-height: 16px;
            padding: 15px;
            text-align: center;

            .label {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            svg {
                font-size: 15px;
                position: relative;
                top: -1px;
            }

            .form-control-file {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 99;
                display: block;
                opacity: 0;
            }
        }

        .continue,
        .previous {
            margin-top: 24px;

            .spinner-grow {
                margin-right: 10px;
                position: relative;
                top: -2px;
            }

            .spinner-border {
                border-width: 2px;
            }
        }

        .previous {
            float: left;
        }

        .previous ~ .continue {
            margin-left: 20px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .broker {
        padding: 0 20px;

        .register {
            margin-top: 12px;
            padding: 40px 28px 50px 28px;

            .first-name,
            .last-name,
            .phone,
            .email,
            .state,
            .postcode {
                display: block;
                width: auto;
                margin-left: 0;
                float: none;
            }

            .continue {
                display: block;
                margin: 26px auto 0 auto;
            }
       }
    }
}

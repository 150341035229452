@import '~/variables.less';

.investor main .body.statements {
    h1 {
        text-align: center;
    }

    .alert {
        max-width: 520px;
        margin: 0 auto;
        font-size: 15px;
        text-align: center;
        background-color: #d2eded;
        border-radius: 8px;
        box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
        padding: 30px 40px;
        margin-top: 40px;
        border: 0;

        .alert-heading {
            font-size: 17px;
            font-weight: 500;
            font-family: 'Poppins Bold';
        }
    }

    .statements {
        max-width: 506px;
        margin: 0 auto;

        .content-box {
            text-align: center;

            p {
                font-size: 15px;
            }

            .btn {
                margin-top: 25px;
                background-color: #d2eded;
                border-radius: 50px;
                color: @primary1;
                border: none;
                font-size: 11px;
                height: auto;
                width: 268px;
                padding: 12px;
                font-family: 'Poppins SemiBold';

                &:hover {
                    color: @primary1;
                }

                &:active {
                    background-color: #d2eded;
                }

                svg {
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: -1px;
                    margin-left: 8px;
                }
            }

            .pagination-block {
                width: 100%;
                display: inline-block;
                margin-top: 30px;
                clear: both;

                @media only screen and (max-width: 991px) {
                    width: 100%;
                }

                .pagination {
                    display: inline-block;
                    width: fit-content;
                    margin-bottom: 0;

                    .page-item {
                        display: inline-block;

                        .page-link {
                            border: 0;
                            width: 26px;
                            height: 26px;
                            text-align: center;
                            font-size: 14px;
                            line-height: 26px;
                            padding: 0;
                            color: @primary2;
                            font-family: 'Poppins SemiBold';
                            margin-left: 4px;
                            border-radius: 6px;
                        }

                        &:first-child {
                            .page-link {
                                margin-left: 0;
                            }
                        }

                        &.active {
                            .page-link {
                                background-color: @primary1;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

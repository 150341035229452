@import '~/variables.less';

.investor main .body.income-trust-portfolio {
    margin: 0 auto;
    max-width: 1190px;

    .boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .box {
        border-radius: 15px;
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.15);
        flex-basis: 33%;
        margin: 28px 15px 15px;
        padding: 24px;
        text-align: center;

        .label {
            font-family: 'Poppins SemiBold', sans-serif;
        }

        .value {
            font-family: 'Poppins Bold', sans-serif;
        }

        .primary {
            .label {
                font-size: 17px;
            }

            .value {
                font-size: 38px;
            }
        }
    }

    .table.income-trusts {
        border-left: 0;
        border-right: 0;
        color: @primary1;
        font-size: 12px;
        left: -40px;
        margin: 40px 0 0;
        position: relative;
        width: calc(100% + 80px);

        th {
            background-color: #fafafa;
            border-bottom-width: 1px;
            color: @primary2;
            font-size: 11px;
            font-weight: normal;
            text-transform: uppercase;
        }

        th,
        td {
            border-left: 0;
            border-right: 0;
            padding: 16px;
            width: calc(50% / 3);

            &:first-child {
                padding-left: 40px;
            }

            &:last-child {
                padding-right: 40px;
            }
        }

        td.investment {
            width: 50%;
        }
    }
}

@media only screen and (max-width: 991px) {
    .investor main .body.income-trust-portfolio {
        .boxes {
            flex-direction: column;
        }

        .table.income-trusts {
            border-bottom: 0;
            border-top: 0;
            left: -15px;
            width: calc(100% + 30px);

            thead {
                display: none;
            }

            tr {
                display: block;
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }
            }

            td {
                border: none;
                display: block;
                padding: 15px 30px;
                text-align: right;
                width: 100%;

                &:first-child,
                &:last-child {
                    padding: 15px 30px;
                }

                &:before {
                    color: @primary2;
                    content: attr(data-label);
                    float: left;
                    text-transform: uppercase;
                }
            }

            td.investment {
                background-color: #fafafa;
                border-bottom: 1px solid #dee2e6;
                border-top: 1px solid #dee2e6;
                font-weight: bold;
                width: 100%;

                &:before {
                    color: @primary1;
                }
            }
        }
    }
}

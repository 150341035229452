@import '../variables.less';

.public {
    h3 {
        font-family: 'Poppins Bold', sans-serif;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: @primary1;
    }

    h4 {
        font-family: 'Poppins Medium', sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: @primary1;
    }

    .top-bar {
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        background-color: #fff;
        height: 64px;

        .logo {
            height: 30px;
            margin-top: 3px;
        }

        .login {
            font-weight: 600;
            font-size: 14px;
            float: right;
            width: 90px;
            height: 34px;
            border-radius: 54px;
            padding: 5px 24px;
            margin-top: 0;
            width: auto !important;
        }

        .phone {
            font-family: 'Poppins SemiBold', sans-serif;
            float: right;
            font-weight: 600;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin: 6px 45px 0 0;
            font-size: 14px;
            color: @primary1;

            svg {
                font-size: 16px;
                position: relative;
                transform: rotate(10deg);
                font-weight: bold;
            }
        }
    }

    .btn {
        border-radius: 54px;
        height: 45px;
        font-size: 14px;
        padding: 10px;
        border-width: 2px;

        &.btn-primary,
        &.btn-primary a {
            background-color: @primary1;
            border-color: @primary1;
            color: #fff;
            width: 240px;

            &:not(:disabled):not(.disabled):hover {
                color: #fff;
                box-shadow: 0 6px 20px rgba(22, 37, 76, 0.6);
            }

            &:not(:disabled):not(.disabled):active {
                background: transparent;
                color: @primary1;
                border-color: @primary1;
            }

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):active:focus {
                box-shadow: none;
            }
        }
    }

    .form-control {
        border: 1px solid @primary3;
        border-radius: 6px;
        height: 48px;
        font-size: 15px;
        color: @primary1;

        &:not(:disabled):not(.disabled):focus {
            @input-glow();
            color: @primary1;
        }

        &.is-invalid {
            border-color: @invalid;
        }

        &.is-invalid:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .rdt.is-invalid .form-control {
        border-color: @invalid;

        &:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .invalid-feedback {
        color: @invalid;
    }

    .form-group {
        .form-label {
            color: @typography1;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            color: @primary2;
        }

        .form-check {
            display: block;
            position: relative;
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.form-check-inline {
                display: inline;
            }

            &.radio {
                padding-left: 26px;

                label {
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: uppercase;
                }
            }

            label {
                color: @primary1;
                font-size: 13px;
                cursor: pointer;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                cursor: pointer;
                position: absolute;
                top: 7px;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #fff;
                border: 1px solid #c1c5cf;
                border-radius: 6px;
            }

            input[type=radio] ~ .checkmark {
                border-radius: 10px;
                width: 20px;
                height: 20px;
                top: 8px;
            }

            input[type=checkbox]:checked ~ .checkmark {
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.15);
            }

            input[type=radio]:checked ~ .checkmark {
                background-color: @secondary1;
                border-color: @secondary1;
            }

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            input:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                left: 8px;
                top: 5px;
                width: 6px;
                height: 10px;
                border: solid @secondary1;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            input[type=radio]:checked ~ .checkmark:after {
                border-color: #fff;
                left: 7px;
                top: 4px;
                width: 5px;
                height: 8px;
            }
        }

        .form-control.is-invalid {
            background-image: none;
        }
    }

    a,
    a:hover,
    a:active,
    a:visited {
        color: @secondary1;
    }

    .badges {
        margin-top: 70px;
        text-align: center;

        img {
            margin: 20px;
            width: 90px;
        }
    }

    .questions-wrapper {
        color: @primary1;
        text-align: center;
        margin-top: 70px;
        background-color: #f8f8f8;
        padding: 40px 0;
        font-weight: 400;
        font-size: 15px;

        .questions {
            .phone,
            .email {
                font-weight: bold;
            }
        }
    }

    .footer-wrapper {
        background-color: @primary1;
        color: #fff;
        padding: 77px 0 93px 0;
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        text-align: justify;

        strong,
        a {
            font-weight: 400;
        }
    }

    @media only screen and (max-width: 768px) {
        .top-bar {
            .phone {
                display: none;
            }
        }

        .badges {
            margin-top: px;
            text-align: center;

            img {
                margin: 10px;
                width: 55px;
            }
        }

        .footer-wrapper {
            padding: 22px;

            .footer {
                padding: 0;

                p {
                    margin: 0;
                }
            }
        }
    }
}
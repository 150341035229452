@import '~/variables.less';

.investor main .body.dashboard {
    max-width: 1190px;
    margin: 0 auto;

    .alert {
        margin: 0 auto;
        font-size: 15px;
        background-color: #E2F6E9 ;
        border-radius: 8px;
        box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.15);
        padding: 30px 40px;
        margin-top: 40px;
        border: 0;

        @media only screen and (max-width: 991px) {
            padding: 20px 15px;
            margin-top: 30px;
        }
        
        .alert-heading {
            font-size: 17px;
            font-weight: 500;
            font-family: 'Poppins Bold';
        }
    }

    .portfolio-snapshot {
        .boxes {
            margin: 0 -15px;
            display: flex;

            @media only screen and (max-width: 991px) {
                display: block;
                margin: 0;
            }

            .box {
                max-width: 320px;
                margin: 28px 15px 15px 15px;
                box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.15);
                border-radius: 15px;
                padding: 24px;
                flex: 1;

                .label {
                    text-align: center;
                    font-family: 'Poppins SemiBold';
                }

                .value {
                    text-align: center;
                    font-family: 'Poppins Bold';
                }

                .primary {
                    .label {
                        font-size: 17px;
                    }

                    .value {
                        font-size: 38px;
                    }
                }

                .secondary {
                    color: @primary2;
                    margin-top: 12px;

                    .label {
                        font-size: 14px;
                    }

                    .value {
                        font-size: 20px;
                    }
                }

                @media only screen and (max-width: 991px) {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    margin: 16px 0 0 0;
                    flex: 0;

                    &:first-of-type {
                        margin-top: 28px;
                    }
                }
            }
        }
    }

    .available-investments,
    .latest-funded {
        padding-right: 10px;

        @media only screen and (max-width: 991px) {
            padding-right: 15px;
        }
    }

    .investment {
        width: 320px;
        border: 1px solid #e8e8e8;
        display: inline-block;
        margin: 15px 30px 15px 0;
        overflow: hidden;
        box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.15);
        border-radius: 15px;
        color: @primary1;
        text-decoration: none;

        @media only screen and (max-width: 991px) {
            width: 100%;
            display: block;
        }

        .photo {
            overflow: hidden;
            height: 182px;
            background-color: #e8e8e8;

            img {
                width: 100%;
                margin-top: -50px;
            }
        }

        .investment-progress {
            margin-top: 13px;
            padding: 0 16px;
            text-transform: uppercase;
            font-size: 11px;
            font-family: 'Poppins Medium';

            .progress-graph {
                .progress {
                    height: 11px;
                    border-radius: 30px;
                    background-color: #d2eded;

                    .progress-bar {
                        border-radius: 30px;
                        background-color: @secondary1;
                    }
                }
            }

            .funded,
            .remaining {
                display: inline-block;
                width: 50%;
                margin-top: 8px;
                color: @primary2;
            }

            .remaining {
                text-align: right;
            }
        }

        .name {
            font-size: 20px;
            padding: 15px 15px 0 15px;
            font-family: 'Poppins SemiBold';
            height: 45px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .code {
            font-size: 12px;
            padding: 0 15px 15px 15px;
            font-family: 'Poppins SemiBold';
        }

        .target-return,
        .lvr,
        .term,
        .term-remaining,
        .available {
            display: inline-block;
            margin-left: 15px;

            .label {
                font-size: 11px;
                text-transform: uppercase;
                font-family: 'Poppins Medium';
                color: @primary2;
            }

            .value {
                font-size: 14px;
                font-family: 'Poppins SemiBold';
            }
        }

        .target-return {
            width: 86px;
        }

        .lvr {
            width: 47px;
        }

        .term,
        .term-remaining {
            width: 140px;

            .number {
                display: inline;
            }

            .suffix {
                display: inline;
                font-size: 11px;
                font-weight: normal;
                margin: 0 8px 0 4px;
            }
        }

        .btn {
            display: inline-block;
            margin: 16px 0 15px 15px;
            width: calc(100% - 30px);
        }
    }

    .dual-box {
        display: flex;
        width: 100%;
        flex-direction: row;
        column-gap: 40px;

        @media only screen and (max-width: 991px) {
            flex-direction: column;
        }

        .latest-statements {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            padding-bottom: 60px;

            h2 {
                margin-top: 30px;
            }

            p {
                margin-top: 30px;
                font-size: 15px;
            }

            .btn {
                margin-top: 25px;
                background-color: #d2eded;
                color: @primary1;
                border: none;
                font-size: 11px;
                height: auto;
                width: 268px;
                padding: 12px;
                font-family: 'Poppins SemiBold';

                &:hover {
                    color: @primary1;
                }

                &:active {
                    background-color: #d2eded;
                }

                svg {
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: -1px;
                    margin-left: 8px;
                }
            }
        }

        .disclosure-documents {
            text-align: center;
            padding-bottom: 60px;

            h2 {
                margin-top: 30px;
            }

            p {
                margin-top: 25px;
                margin-bottom: 0;
                font-size: 15px;
            }

            .btn {
                margin-top: 25px;
                background-color: #d2eded;
                color: @primary1;
                border: none;
                font-size: 11px;
                height: auto;
                width: 268px;
                padding: 12px;
                font-family: 'Poppins SemiBold';

                &:hover {
                    color: @primary1;
                }

                &:active {
                    background-color: #d2eded;
                }

                svg {
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: -1px;
                    margin-left: 8px;
                    padding: 0px;
                }
            }

            svg {
                width: 100px;
                height: 100px;
                padding: 5px;
            }
        }
    }
}

@import '~/variables.less';

.investor main .body.get-legal-document {
    max-width: 506px;
    margin: 0 auto;

    .content-box {
        text-align: center;
        padding-bottom: 60px;

        h2 {
            margin-top: 30px;
        }

        p {
            margin-top: 30px;
            font-size: 15px;
        }

        .btn {
            margin-top: 25px;
            background-color: #d2eded;
            border-radius: 50px;
            color: @primary1;
            border: none;
            font-size: 11px;
            height: auto;
            width: 268px;
            padding: 12px;
            font-family: 'Poppins SemiBold';

            &:hover {
                color: @primary1;
            }

            &:active {
                background-color: #d2eded;
            }

            svg {
                width: 18px;
                height: 18px;
                position: relative;
                top: -1px;
                margin-left: 8px;
            }
        }
    }
}

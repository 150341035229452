@import './variables.less';
@import './UI/fonts/Poppins/poppins.less';

html {
    height: 100%;
}

body {
    height: 100%;
    background-color: #fff;
    font-family: 'Poppins Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.btn {
    border-radius: 54px;
}

.modal-dialog {
    max-width: 580px;
    margin-top: 180px;
    color: @primary1;

    .btn {
        border-radius: 54px;
        height: 45px;
        font-size: 14px;
        padding: 10px;
        border-width: 2px;

        &.btn-primary,
        &.btn-primary a {
            background-color: @primary1;
            border-color: @primary1;
            color: #fff;
            width: 240px;

            &:not(:disabled):not(.disabled):hover {
                color: #fff;
                box-shadow: 0 6px 20px rgba(22, 37, 76, 0.6);
            }

            &:not(:disabled):not(.disabled):active {
                background: transparent;
                color: @primary1;
                border-color: @primary1;
            }

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):active:focus {
                box-shadow: none;
            }
        }
    }

    .form-control {
        border: 1px solid @primary3;
        border-radius: 6px;
        height: 48px;
        font-size: 15px;
        color: @primary1;

        &:not(:disabled):not(.disabled):focus {
            @input-glow();
            color: @primary1;
        }

        &.is-invalid {
            border-color: @invalid;
        }

        &.is-invalid:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .rdt.is-invalid .form-control {
        border-color: @invalid;

        &:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .invalid-feedback {
        color: @invalid;
    }

    .form-group {
        .form-label {
            color: @typography1;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            color: @primary2;
        }

        .form-check {
            display: block;
            position: relative;
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.form-check-inline {
                display: inline;
            }

            &.radio {
                padding-left: 26px;

                label {
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: uppercase;
                }
            }

            label {
                color: @primary1;
                font-size: 13px;
                cursor: pointer;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                cursor: pointer;
                position: absolute;
                top: 7px;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #fff;
                border: 1px solid #c1c5cf;
                border-radius: 6px;
            }

            input[type=radio] ~ .checkmark {
                border-radius: 10px;
                width: 20px;
                height: 20px;
                top: 8px;
            }

            input[type=checkbox]:checked ~ .checkmark {
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.15);
            }

            input[type=radio]:checked ~ .checkmark {
                background-color: @secondary1;
                border-color: @secondary1;
            }

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            input:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                left: 8px;
                top: 5px;
                width: 6px;
                height: 10px;
                border: solid @secondary1;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            input[type=radio]:checked ~ .checkmark:after {
                border-color: #fff;
                left: 7px;
                top: 4px;
                width: 5px;
                height: 8px;
            }
        }
    }

    .form-control.is-invalid {
        background-image: none;
    }

    a,
    a:hover,
    a:active,
    a:visited {
        color: @secondary1;
    }

    .modal-content {
        box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
        border-radius: 8px;
        border: 0;

        .modal-header {
            border-bottom: 0;
            padding: 30px 40px 0 40px;

            .modal-title {
                font-size: 17px;
                font-family: 'Poppins Bold';
            }

            .close {
                width: 30px;
                height: 30px;
                background-color: #f7f5f9;
                border-radius: 30px;
                padding: 0;
                margin: -10px -15px 0 0;
                color: @primary1;
                opacity: 1;
            }
        }

        .modal-body {
            padding: 6px 40px 40px 40px;
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 991px) {
        margin-top: 160px;

        .modal-content {
            max-width: 100%;
            width: 100%;

            .modal-header {
                .modal-title {
                    width: 100%;
                    text-align: center;
                    margin-top: 5px;
                }
            }

            .modal-body {
                text-align: center;
                padding: 30px 20px 40px 20px;

                .form-group {
                    text-align: left;
                }
            }
        }
    }
}

@import '../variables.less';

.broker main .body.team {
    max-width: 1190px;
    margin: 0 auto;

    h2 {
        font-family: 'Poppins Bold';
        font-size: 17px;
        color: @primary1;
        width: 100%;
        margin-bottom: 20px;
    }

    .team-main {
        margin-top: 40px;

        .team-box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 28px;
            box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
            border-radius: 8px;
            padding: 30px 40px;
            background-color: #fff;

            .support-team {
                flex-direction: column;

                a {
                    color: @primary1;
                    font-size: 17px;
                    font-family: 'Poppins SemiBold';
                }
            }

            .members {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: left;

                @media only screen and (max-width: 768px) {
                    justify-content: center;
                }

                .member {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    flex-basis: 33%;

                    img {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                    }

                    p {
                        margin: 2px;
                        font-size: 13px;
                        inline-size: 200px;
                    }

                    .name {
                        margin-top: 10px;
                        font-family: 'Poppins Bold';
                        font-size: 18px;
                    }

                    .title {
                        font-family: 'Poppins SemiBold';
                    }
                }
            }
        }
    }
}
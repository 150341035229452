@import '../variables.less';

.user-email-change-modal {
    .modal-content {
        .modal-body {
            padding-top: 30px;

            .btn-primary {
                width: 180px;
                margin-top: calc(30px - 1rem);
            }
        }
    }
}

.user-password-change-modal {
    .modal-content {
        .modal-body {
            padding-top: 30px;

            .btn-primary {
                width: 180px;
                margin-top: calc(30px - 1rem);
            }
        }
    }
}


@import '../variables.less';

.broker main .body.product-and-tools {
    max-width: 1190px;
    margin: 0 auto;
    min-height: 90vh;

    .main {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;

        @media only screen and (max-width: 768px) {
            justify-content: space-evenly;
        }

        justify-content: space-between;

        .content-box {
            background-color: #fff;
            box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
            border-radius: 8px;
            padding: 30px 30px;
            min-height: 510px;
            width: 31%;
            margin: 0px 15px 15px 15px;
            flex-direction: column;

            .image {
                width: 100px;
                margin: 0 auto;
            }

            .content {
                margin: 10px 0;
                text-align: center;

                .label {
                    font-size: 17px;
                    font-family: 'Poppins SemiBold';
                    margin-bottom: 20px;
                }

                .detail {
                    margin: 0px 25px;
                    min-height: 75px;
                    max-height: 75px;
                    font-size: 15px;
                }
            }

            .document-link {
                margin-top: 25px;

                button {
                    background-color: #d2eded;
                    color: @primary1;
                    border: none;
                    border-radius: 30px;
                    font-size: 13px;
                    margin: 0 auto;
                    font-family: 'Poppins Bold';
                    width: 100%;

                    &:hover {
                        color: @primary1;
                    }

                    &:active {
                        background-color: #d2eded;
                    }
                }
            }

            &:first-of-type {
                margin-left: 0px;
            }

            &:last-of-type {
                margin-right: 0px;
            }

            @media only screen and (max-width: 991px) {
                display: block;
                max-width: 100%;
                width: 100%;
                margin: 16px 0 0 0;
                flex: 0;

                &:first-of-type {
                    margin-top: 28px;
                }
            }

            @media only screen and (max-width: 768px) {
                flex: none;
                min-height: auto;
            }
        }
    }
}

@import '~/variables.less';

.investor main .body.refer-friend {
    max-width: 787px;
    margin: 0 auto;
    font-size: 13px;

    .your-referrals {
        img {
            float: right;
            width: 208px;
            height: 161px;
            margin-left: 40px;
            position: relative;
            top: calc(-20px - 0.5rem);

            @media only screen and (max-width: 991px) {
                float: none;
                position: static;
                margin: 20px auto 0 auto;
                display: block;
            }
        }

        p {
            margin-top: 30px;

            @media only screen and (max-width: 991px) {
                margin-top: 20px;
            }
        }
    }

    .email-friend {
        .form-group {
            margin-top: 20px;
            width: calc(100% - 113px);
            display: inline-block;
            height: 48px;

            .form-control {
                color: @primary2;
            }

            @media only screen and (max-width: 991px) {
                width: calc(100% - 50px);
                height: 40px;

                .form-control {
                    height: 40px;
                }
            }
        }

        .invite {
            width: 103px;
            height: 48px;
            float: right;
            margin-top: 20px;

            .icon {
                display: none;
            }

            @media only screen and (max-width: 991px) {
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 17px;

                .icon {
                    display: inline;
                    width: 18px;
                    height: 18px;
                }

                .text {
                    display: none;
                }
            }
        }
    }

    .invite-link {
        .form-group {
            margin-top: 20px;
            width: calc(100% - 113px);
            display: inline-block;
            height: 48px;

            @media only screen and (max-width: 991px) {
                width: calc(100% - 50px);
                height: 40px;

                .form-control {
                    height: 40px;
                }
            }

            .form-control {
                background-color: #fff;
                color: @primary2;
            }
        }

        .copy {
            width: 103px;
            height: 48px;
            float: right;
            margin-top: 20px;

            .icon {
                display: none;
            }

            @media only screen and (max-width: 991px) {
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 17px;

                .icon {
                    display: inline;
                    width: 18px;
                    height: 18px;
                }

                .text {
                    display: none;
                }
            }
        }
    }

    .share-social {
        button {
            width: 125px;
            height: 45px;
            color: #fff !important;
            font-size: 12px !important;
            font-family: 'Poppins Medium' !important;
            border-radius: 100px;
            margin-right: 16px;
            margin-top: 20px;

            svg {
                width: 17px;
                height: 17px;
                position: relative;
                top: -2px;
                margin-right: 2px;
            }

            &.facebook {
                background-color: #006fea !important;
            }

            &.twitter {
                background-color: #339aea !important;
            }

            &.linkedin {
                background-color: #006da8 !important;
            }
        }
    }

    .referral-status {
        table.referrals {
            font-size: 13px;
            margin-top: 30px;
            width: calc(100% + 80px);
            position: relative;
            left: -40px;
            border-left: 0;
            border-right: 0;
            color: @primary1;
            margin-bottom: 0;

            @media only screen and (max-width: 991px) {
                left: -15px;
                width: calc(100% + 30px);
                border-top: 0;
                border-bottom: 0;
            }

            td {
                border-left: 0;
                border-right: 0;
                padding: 16px;

                @media only screen and (max-width: 991px) {
                    border-top: 0;
                    border-bottom: 0;
                }

                &:first-child {
                    padding-left: 40px;
                }

                &:last-child {
                    padding-right: 40px;
                }

                &.amount {
                    font-family: 'Poppins SemiBold';
                }
            }

            @media only screen and (max-width: 991px) {
                tr {
                    display: block;
                }

                td {
                    display: block;
                    padding: 0;
                }

                td.date {
                    width: 100%;
                    padding: 6px 15px;
                    background-color: #fafafa;
                    text-transform: uppercase;
                    font-family: 'Poppins Medium';
                }

                td.email {
                    margin-top: 15px;
                    margin-left: 15px;
                    height: 18px;
                    font-family: 'Poppins Medium';
                }

                td.status {
                    font-size: 11px;
                    margin-left: 15px;
                    margin-top: 2px;
                    font-family: 'Poppins Medium';
                    margin-bottom: 15px;
                    width: 40%;
                    height: 16px;
                }

                td.amount {
                    float: right;
                    font-size: 14px;
                    font-family: 'Poppins Medium';
                    height: 21px;
                    margin-right: 15px;
                    position: relative;
                    top: -30px;
                    text-align: right;
                    width: 40%;
                    margin-bottom: -21px;
                    padding-right: 0;
                }
            }
        }
    }
}

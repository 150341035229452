@primary1: #140d2f;
@primary2: #5a6580;
@primary3: #c1c5cf;
@secondary1: #59bec9;
@secondary2: #81d2d4;
@secondary3: #d2eded;
@accent1: #5dc4ed;
@accent2: #84d1f0;
@accent3: #d3edf7;
@typography1: #140d2f;
@typography2: #91d1d2;
@typography3: #daf0f0;
@semantic12: #d5f2ea;
@neutral5: #f7f5f9;
@invalid: #eb5757;

@input-glow: {
    border-color: @secondary2;
    box-shadow: 0 0 4px rgba(89, 197, 199, 0.75);
}

@input-glow-invalid: {
    border-color: @invalid;
    box-shadow: 0 0 4px rgba(235, 57, 57, 0.75);
}

@secondary-button: {
    background-color: @neutral5;
    height: 35px;
    border-radius: 20px;
    color: @primary2;
    border: none;
    padding: 0 17px;
    font-size: 11px;
    width: auto;
    line-height: 35px;

    &:not(:disabled):not(.disabled):hover {
        color: @primary2;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active {
        background-color: @neutral5;
        color: @primary2;
    }

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }
}

@secondary-button-large: {
    @secondary-button();
    height: 45px;
    padding: 0px 28px;
    border-radius: 50px;
    line-height: 45px;
}

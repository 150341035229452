@import '../variables.less';

.borrower {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    color: @primary1;

    &> .spinner-border {
        margin: auto auto;
    }

    .btn {
        border-radius: 54px;
        height: 45px;
        font-size: 14px;
        padding: 10px;
        border-width: 20px;

        &.btn-primary,
        &.btn-primary a {
            background-color: @primary1;
            border-color: @primary1;
            color: #fff;
            width: 240px;

            &:not(:disabled):not(.disabled):hover {
                color: #fff;
                box-shadow: 0 6px 20px rgba(22, 37, 76, 0.6);
            }

            &:not(:disabled):not(.disabled):active {
                background: transparent;
                color: @primary1;
                border-color: @primary1;
            }

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):active:focus {
                box-shadow: none;
            }
        }
    }

    .form-control {
        border: 1px solid @primary3;
        border-radius: 6px;
        height: 48px;
        font-size: 15px;
        color: @primary1;

        &:not(:disabled):not(.disabled):focus {
            @input-glow();
            color: @primary1;
        }

        &.is-invalid {
            border-color: @invalid;
        }

        &.is-invalid:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .rdt.is-invalid .form-control {
        border-color: @invalid;

        &:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .invalid-feedback {
        color: @invalid;
    }

    .form-group {
        .form-label {
            color: @typography1;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            color: @primary2;
        }

        .form-check {
            display: block;
            position: relative;
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.form-check-inline {
                display: inline;
            }

            &.radio {
                padding-left: 26px;

                label {
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: uppercase;
                }
            }

            label {
                color: @primary1;
                font-size: 13px;
                cursor: pointer;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                cursor: pointer;
                position: absolute;
                top: 7px;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #fff;
                border: 1px solid #c1c5cf;
                border-radius: 6px;
            }

            input[type=radio] ~ .checkmark {
                border-radius: 10px;
                width: 20px;
                height: 20px;
                top: 8px;
            }

            input[type=checkbox]:checked ~ .checkmark {
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.15);
            }

            input[type=radio]:checked ~ .checkmark {
                background-color: @secondary1;
                border-color: @secondary1;
            }

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            input:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                left: 8px;
                top: 5px;
                width: 6px;
                height: 10px;
                border: solid @secondary1;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            input[type=radio]:checked ~ .checkmark:after {
                border-color: #fff;
                left: 7px;
                top: 4px;
                width: 5px;
                height: 8px;
            }
        }
    }

    .form-control.is-invalid {
        background-image: none;
    }

    a,
    a:hover,
    a:active,
    a:visited {
        color: @secondary1;
    }

    .top-bar {
        background-color: #fff;
        height: 64px;

        > .container-fluid {
            padding: 0;
        }

        .logo {
            height: 28px;
            margin-top: 3px;
        }

        .auth {
            position: absolute;
            right: 45px;
            font-size: 14px;
            color: @primary1;
            top: 10px;

            a {
                font-size: 14px;
                color: @primary1;
            }

            .dropdown-toggle {
                background-color: #daf0f0;
                border-radius: 100px;
                width: 45px;
                height: 45px;
                font-size: 24px;
                line-height: 42px;
                padding: 0;
                text-align: center;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                padding: 0;
                border: 0;
                box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
                border-radius: 6px;

                a {
                    color: @primary1;
                    text-decoration: none;
                    font-family: 'Poppins Medium';
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    padding: 0 20px;

                    &:hover {
                        background-color: #fff;
                        color: @primary1;
                    }

                    &.refer-friend,
                    &.help-centre {
                        background-color: @secondary3;
                    }

                    &.active {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    main {
        display: flex;
        flex-direction: row;
        flex: 1;

        .right-section {
            width: 100%;
            display: flex;
            background-color: #f9f9f9;
        }

        .body {
            padding: 55px 45px;
            width: 100%;

            h1 {
                font-size: 24px;
                font-family: 'Poppins Bold';
                color: @primary1;
            }

            .main-box {
                background-color: #fff;
                box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
                border-radius: 8px;
                padding: 30px 40px;
                margin-top: 40px;
                width: 100%;

                &:first-child {
                    margin-top: 0;
                }

                h2 {
                    font-family: 'Poppins Bold';
                    font-size: 17px;
                    color: @primary1;
                }
            }
        }
    }

    .toasts {
        width: 350px;
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 10;

        .close {
            display: none;
        }
    }

    @media only screen and (max-width: 991px) {
        .top-bar {
            > .container-fluid {
                padding: 0;
            }

            .logo {
                margin-left: 0;
                margin-top: 0;
            }

            .auth {
                right: 20px;
            }
        }

        main {
            display: block;

            .body {
                padding: 30px 20px 30px 20px;
                margin: 0;
                min-height: auto;

                .main-box {
                    padding: 20px 15px;
                    margin-top: 15px;

                    &:first-of-type {
                        margin-top: 30px;
                    }
                }

                h2 {
                    font-size: 18px;
                }
            }
        }
    }
}

@import '~/variables.less';

.investor main .body.marketplace-register {
    max-width: 790px;
    margin: 0 auto;

    .content-box {
        padding: 70px 100px 60px 100px;

        h3 {
            font-family: 'Poppins Bold', sans-serif;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            color: @primary1;
        }

        h3 + p:not(.intro) {
            margin-top: 24px;
        }

        h4 {
            font-family: 'Poppins Medium', sans-serif;
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            color: @primary1;
            margin-top: 16px;
        }

        .intro {
            color: @primary1;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 24px;
        }

        .continue,
        .previous {
            display: inline-block;
            margin-top: 24px;

            .spinner-grow {
                margin-right: 10px;
                position: relative;
                top: -2px;
            }

            .spinner-border {
                border-width: 2px;
            }
        }

        .previous {
            float: left;
        }

        .previous ~ .continue {
            margin-left: 20px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .investor main .body.marketplace-register {
        .content-box {
            margin-top: 22px;
            padding: 40px 28px 50px 28px;

            .continue {
                display: block;
                margin: 26px auto 0 auto;
            }

            .previous {
                display: none;
            }
        }
    }
}

@import '../Public/public.less';

.application {
    .steps {
        margin: 80px auto 0 auto;
        width: 536px;

        .line,
        .active-line {
            width: 402px;
            height: 4px;
            background-color: @primary3;
            position: relative;
            left: 67px;
            top: 19px;
            z-index: -1000;
        }

        .active-line {
            z-index: -999;
            background-color: @secondary1;
            top: 15px;
            width: 0;
        }

        &.active-2 .active-line {
            width: 134px;
        }

        &.active-3 .active-line {
            width: 268px;
        }

        &.active-4 .active-line {
            width: 402px;
        }

        .step {
            width: 134px;
            display: inline-block;
            text-align: center;
            color: @primary3;
            font-size: 11px;
            line-height: 13px;

            .number {
                width: 26px;
                height: 26px;
                background-color: @primary3;
                border-radius: 13px;
                border: 2px solid #fff;
                margin: 0 auto;
                font-weight: bold;
                padding-top: 5px;
                color: #fff;
            }

            .label {
                margin-top: 6px;
            }
        }

        &.active-1 .step-1,
        &.active-2 .step-1,
        &.active-2 .step-2,
        &.active-3 .step-1,
        &.active-3 .step-2,
        &.active-3 .step-3,
        &.active-4 .step-1,
        &.active-4 .step-2,
        &.active-4 .step-3,
        &.active-4 .step-4 {
            .number {
                background-color: @secondary1;
            }

            .label {
                color: @primary2;
            }
        }
    }

    .quote,
    .welcome,
    .details,
    .purpose,
    .loan-amount,
    .loan-term,
    .repayment,
    .properties,
    .borrower-type,
    .borrowers,
    .submit,
    .thanks {
        max-width: 700px;
        background-color: #fff;
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        border-radius: 6px;
        padding: 70px 100px 60px 100px;
        font-weight: 400;
        margin-top: 76px;

        h4 {
            margin-top: 16px;
        }

        .intro {
            color: @primary1;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 24px;
        }

        .actions {
            clear: both;

            .continue,
            .previous {
                margin-top: 24px;

                .spinner-grow {
                    margin-right: 10px;
                    position: relative;
                    top: -2px;
                }

                .spinner-border {
                    border-width: 2px;
                }
            }

            .previous {
                float: left;

                .btn {
                    background-color: #d2eded;
                    border-color: #d2eded;
                    color: @primary1;
                    font-weight: bold;

                    &:not(:disabled):not(.disabled):active {
                        background-color: #d2eded;
                        border-color: @primary1;
                    }

                    &:not(:disabled):not(.disabled):hover {
                        color: @primary1;
                    }
                }
            }

            .previous ~ .continue {
                margin-left: 20px;
                display: inline-block;
            }
        }
    }

    .welcome {
        text-align: center;

        h3 svg {
            width: 32px;
            height: 32px;
            margin-left: 12px;
        }

        .intro {
            margin-top: 48px;
        }

        .credit-notice {
            display: none;
        }
    }

    .purpose {
        .purpose-button {
            width: 160px;
            height: 135px;
            margin: 0 10px 10px 0;
            border: 1px solid #c1c5cf;
            background-color: #fff;
            border-radius: 6px;
            font-size: 16px;
            color: @primary1;

            &.active {
                border-color: @secondary1;
                background-color: #d2eded;
                box-shadow: none;
            }

            &:nth-of-type(3),
            &:nth-of-type(6),
            &:nth-of-type(9) {
                margin-right: 0;
            }

            &:nth-of-type(10) {
                margin-right: 300px;
            }

            svg {
                width: 32px;
                height: 32px;
                display: block;
                margin: 0 auto 12px auto;
            }
        }
    }

    .properties {
        h4 {
            clear: both;
            margin-top: 16px;
        }

        .add-property {
            margin-bottom: 1rem;
            background-color: #fff;
            border-color: @primary3;
            color: @primary2;
            border-width: 1px;
            text-transform: uppercase;
            font-size: 11px;

            &:hover {
                color: @primary1 !important;
                border-color: @primary1;
            }
        }

        .delete {
            float: right;
            background-color: #fff;
            border-color: @primary3;
            color: @primary2;
            border-width: 1px;
            text-transform: uppercase;
            font-size: 11px;

            &:hover {
                color: @primary1 !important;
                border-color: @primary1;
            }
        }
    }

    .borrower-type {
        .borrower-type-button {
            width: 160px;
            height: 135px;
            margin: 0 10px 10px 0;
            border: 1px solid #c1c5cf;
            background-color: #fff;
            border-radius: 6px;
            font-size: 16px;
            color: @primary1;
            display: inline-block;

            &.active {
                border-color: @secondary1;
                background-color: #d2eded;
                box-shadow: none;
            }

            &:nth-of-type(3),
            &:nth-of-type(6),
            &:nth-of-type(9) {
                margin-right: 0;
            }

            &:nth-of-type(10) {
                margin-right: 300px;
            }

            svg {
                width: 32px;
                height: 32px;
                display: block;
                margin: 0 auto 12px auto;
            }
        }
    }

    .borrowers {
        h4 {
            clear: both;
            margin-top: 16px;
        }

        .add-borrower {
            margin-bottom: 1rem;
            background-color: #fff;
            border-color: @primary3;
            color: @primary2;
            border-width: 1px;
            text-transform: uppercase;
            font-size: 11px;

            &:hover {
                color: @primary1 !important;
                border-color: @primary1;
            }
        }

        .delete {
            float: right;
            background-color: #fff;
            border-color: @primary3;
            color: @primary2;
            border-width: 1px;
            text-transform: uppercase;
            font-size: 11px;

            &:hover {
                color: @primary1 !important;
                border-color: @primary1;
            }
        }

        .phone,
        .dob,
        .dependent-number,
        .licence-state,
        .passport-country {
            clear: both;
            float: left;
            width: 240px;
        }

        .email,
        .gender,
        .licence-number,
        .marital-status,
        .passport-number {
            float: left;
            width: 240px;
            margin-left: 20px;
        }
    }

    .thanks {
        text-align: center;

        .credit-notice {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .application {
        .steps {
            margin-top: 22px;
            width: 360px;

            .line {
                width: 270px;
                left: 45px;
            }

            .active-line {
                left: 45px;
            }

            &.active-2 .active-line {
                width: 90px;
            }

            &.active-3 .active-line {
                width: 180px;
            }

            &.active-4 .active-line {
                width: 270px;
            }

            .step {
                width: 90px;

                .label {
                    display: none;
                }
            }
        }

        .borrower-type,
        .borrowers,
        .details,
        .loan-amount,
        .loan-term,
        .properties,
        .purpose,
        .quote,
        .repayment,
        .submit,
        .thanks,
        .welcome {
            margin-top: 22px;
            padding: 40px 28px 50px 28px;

            .actions {
                display: flex;
                width: 100%;
                flex-direction: row;

                .continue,
                .previous {
                    width: 50%;
                    margin-top: 15px;

                    .btn {
                        width: 100%;
                    }
                }
            }
        }

        .borrowers {
            .add-borrower, .delete {
                width: 100%;
                margin-top: 10px;
            }

            .dependent-number,
            .dob,
            .email,
            .gender,
            .licence-number,
            .licence-state,
            .marital-status,
            .passport-country,
            .passport-number,
            .phone {
                display: block;
                width: auto;
                margin-left: 0;
                float: none;
            }
        }

        .properties {
            .add-property,
            .delete {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}
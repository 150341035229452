@import '../Public/public.less';

.borrow {
    position: relative;

    .top-bar {
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        background-color: #fff;
        height: 64px;
        display: flex;
        padding: 16px 24px;
        position: relative;
        z-index: 1;

        > .container-fluid {
            padding: 0;
        }

        .logo {
            height: 28px;
            margin-top: 3px;
        }

        .phone {
            font-family: 'Poppins SemiBold', sans-serif;
            float: right;
            font-weight: 600;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin: 5px 14px 5px 0;
            font-size: 14px;
            color: @primary1;

            svg {
                font-size: 16px;
                position: relative;
                transform: rotate(10deg);
                font-weight: bold;
            }
        }
    }

    main {
        display: flex;
        flex-direction: row;
        flex: 1;

        p {
            color: @primary1;
            font-weight: 600;
            font-size: 17px;
            line-height: 19px;
        }

        .left-section {
            position: relative;
            width: 255px;
            min-width: 255px;
            height: 100%;
            left: 0px;
            top: 1px;
            margin-top: -1px;
            padding-top: 78px;
            background-color: #fff;
            z-index: 2;

            .left-nav-line {
                position: absolute;
                margin-left: 43px;
                margin-top: 30px;
                width: 5px;
                background-color: #D2EDED;
                height: 160px;
            }

            .step {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #232222;
                padding: 12px 18px;
                margin: 5px 15px;
                font-size: 13px;
                gap: 1.5em;

                svg {
                    margin-right: 18px;
                    width: 18px;
                    height: 18px;
                }

                .step-number {
                    position: relative;
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    border-radius: 50%;
                    background-color: @primary1;
                    color: #fff;
                    text-align: center;
                    font-weight: 700;
                    font-size: 13px;
                }

                &.active {
                    color: #fff;
                    background-color: @primary1;
                    border-radius: 6px;

                    .step-number {
                        background-color: #fff;
                        color: @primary1;
                    }
                }
            }
        }

        .main-section {
            width: 100%;
            display: flex;
            background-color: #f8f8f8;

            .body {
                padding: 80px 30px 100px;
                margin: auto;

                h1 {
                    font-size: 24px;
                    font-family: 'Poppins Bold';
                    color: @primary1;
                }

                h3 {
                    font-family: 'Poppins Bold', sans-serif;
                    font-weight: bold;
                    font-weight: 700;
                    font-size: 38px;
                    line-height: 50px;
                    color: @primary1;
                }

                .main-box {
                    background-color: #fff;
                    box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
                    padding: 55px 75px;
                    width: 810px;
                    margin-left: -300px;
                    gap: 16px;
                    border-radius: 6px;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 19px;
                    display: flex;
                    flex-direction: column;

                    &:first-child {
                        margin-top: 0;
                    }

                    h4 {
                        clear: both;
                        margin-top: 16px;
                        font-weight: 600;
                    }

                    h2 {
                        font-family: 'Poppins Bold';
                        font-size: 17px;
                        color: @primary1;
                    }

                    .progress {
                        margin-bottom: 20px;
                        border-radius: 10px;
                        height: 10px;
                        background-color: @secondary3;
                        transition: 1s ease;
                        transition-delay: 0.5s;

                        .progress-bar {
                            background: @secondary1;
                            border-radius: 10px;
                            transition: 1s ease;
                            transition-delay: 0.5s;
                        }
                    }

                    .fine-print {
                        padding: 15px 20px;
                        background: #F8F8F8;
                        border-radius: 6px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: @primary1;
                        text-align: left;
                    }

                    .badges {
                        width: 100%;
                        display: flex;
                        flex-direction: space-between;
                        margin: 16px 0 0;
                        gap: 5px;

                        img {
                            width: 60px;
                            margin: auto;
                        }
                    }

                    .spinner-border {
                        text-align: center;
                        align-self: center;
                    }

                    .actions {
                        clear: both;
                        margin-bottom: 18px;
                        min-width: 100%;
                        display: flex;
                        justify-content: space-between;

                        .continue,
                        .previous {
                            width: 48.5%;

                            .spinner-grow {
                                margin-right: 10px;
                                position: relative;
                                top: -2px;
                            }

                            .spinner-border {
                                border-width: 2px;
                            }
                        }

                        .continue {
                            background-color: @primary1;
                            border-color: @primary1;
                            height: 45px;
                        }

                        .previous {
                            .btn {
                                width: 100%;
                                height: 45px;
                                background-color: @secondary3 !important;
                                border-color: @secondary3 !important;
                                color: @primary1 !important;
                                font-weight: bold !important;
                            }
                        }
                    }

                    .term-months {
                        .form-label {
                            width: 100%;
                            text-align: center;
                            border: 1px solid #C1C5CF;
                            border-radius: 6px;
                            font-weight: 600;
                            font-size: 17px;
                            line-height: 50px;
                            color: @primary1;
                            text-transform: capitalize;
                            display: flex;
                            justify-content: center;
                            align-content: center;

                            .months {
                                padding-right: 30px;
                            }
                        }

                        .form-control {
                            width: 10%;
                            height: inherit;
                            text-align: right;
                            border-color: transparent;
                            border-radius: 6px;
                            border: none !important;
                            background: transparent;
                            box-shadow: 0 0 0 transparent !important;
                            font-weight: 600;
                            font-size: 17px;
                            line-height: 50px;
                            padding: 0 6px 0 0;

                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                            }
                        }

                        .custom-range {
                            margin: 30px 0;
                        }

                        .custom-range::-webkit-slider-thumb {
                            background: lightgray;
                            height: 24px;
                            width: 24px;
                        }

                        .custom-range::-webkit-slider-runnable-track {
                            background: @accent3;
                            height: 16px;
                        }
                    }

                    .loan-purpose {
                        .btn-group {
                            display: flex;
                            width: 100%;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            gap: 10px;

                            .btn {
                                width: 32%;
                                max-width: 32%;
                                height: 130px;
                                background: #FFFFFF;
                                border: 1px solid #C1C5CF;
                                border-radius: 6px;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 20px;
                                color: @primary1;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;
                                align-items: center;
                                margin: auto;
                                padding: 20px;

                                &.active {
                                    font-weight: 600 !important;
                                    background: @secondary3;
                                    border: 1px solid @secondary1;
                                    box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
                                }

                                &:hover {
                                    color: @primary1 !important;
                                    font-weight: 400;
                                    font-size: 15px;
                                    line-height: 20px;
                                }

                                .icon {
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                        }
                    }

                    .property {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        h4 {
                            width: 50%;
                            margin-top: 30px !important;
                        }

                        p {
                            margin-top: 10px;
                        }

                        .unit-number,
                        .street-number,
                        .street-name,
                        .street-type,
                        .state,
                        .postcode {
                            width: 49%;
                        }

                        .suburb,
                        .estimated-value,
                        .current-debt,
                        .zone-type {
                            width: 100%;
                        }

                        .add-property {
                            background: #F8F8F8;
                            border: 0;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            width: 48.5%;
                            height: 45px;

                            &:hover {
                                box-shadow: 0 6px 20px rgba(22, 37, 76, 0.6);
                            }
                        }

                        .remove-property {
                            font-weight: 600;
                            height: auto;
                            background: none;
                            border-width: 0;
                            align-self: flex-end;
                            padding: 8px 0;
                            color: @secondary1;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .comments {
                        clear: both;
                    }

                    .thanks {
                        text-align: center;

                        p {
                            font-weight: 600;
                            font-size: 17px;
                            line-height: 24px;
                            margin: auto;
                        }
                    }

                    .quote-subheading {
                        margin-bottom: 0;
                        padding-left: 12px;
                    }

                    .quote-details {
                        border: 0px;

                        tr:first-child {
                            border-top: 4px solid @secondary1;
                        }

                        th {
                            padding: 12px;
                            background-color: @secondary3;
                            border: inherit;
                            width: 40%;
                            font-weight: 600;
                            border-right: 2px solid white;
                            border-bottom: 2px solid white;
                        }

                        td {
                            padding: 12px;
                            border: inherit;
                            background-color: #F2FAFA;
                            border-bottom: 2px solid white;
                            font-weight: 400;
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .apply-now {
                        margin-bottom: 16px;
                        background-color: @primary1;
                        border-color: @primary1;
                        height: 45px;
                        font-weight: bold !important;
                        width: 100%;
                    }
                }

                .box {
                    display: block;
                    color: @primary1;
                    padding: 22px 22px;
                    background-color: #fff;
                    box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
                    border-radius: 6px;
                    margin-bottom: 30px;
                    width: 270px;
                    position: absolute;
                    margin-left: 540px;

                    &.help {
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 22px 42px;
                        top: 274px;

                        h4 {
                            font-weight: 600;
                            font-size: 17px;
                            line-height: 19px;
                            margin-bottom: 20px
                        }

                        a {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            color: @primary1;
                        }

                        svg {
                            color: @secondary1;
                        }

                        p {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            margin-bottom: 0;
                        }
                    }

                    &.bright {
                        background: @secondary3;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 18px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        top: 144px;

                        p {
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 18px;
                            margin: 0 10px 0 0;
                        }

                        .icon {
                            background-color: #fff;
                            min-width: 50px;
                            line-height: 50px;
                            border-radius: 10px;
                            font-size: 30px;
                            text-align: center;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .footer-wrapper {
        background-color: @primary1 !important;
        color: #fff !important;
        padding: 80px !important;
        font-size: 12px !important;
        line-height: 14px !important;
        font-weight: 300 !important;
        text-align: justify !important;

        strong,
        a {
            font-weight: 400 !important;
        }

        .footer {
            padding: 0 !important;
            margin: 0 !important;
            max-width: none !important;
        }
    }

    a,
    a:hover,
    a:active,
    a:visited {
        color: @secondary1;
    }

    .form-control {
        border: 1px solid @primary3;
        border-radius: 6px;
        height: 48px;
        font-size: 15px;
        color: @primary1;

        &:not(:disabled):not(.disabled):focus {
            @input-glow();
            color: @primary1;
        }

        &.is-invalid {
            border-color: @invalid;
        }

        &.is-invalid:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .rdt.is-invalid .form-control {
        border-color: @invalid;

        &:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .invalid-feedback {
        color: @invalid;
    }

    .form-group {
        .form-label {
            color: @typography1;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            color: @primary2;
        }

        .form-check {
            display: block;
            position: relative;
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.form-check-inline {
                display: inline;
            }

            &.radio {
                padding-left: 26px;

                label {
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: uppercase;
                }
            }

            label {
                color: @primary1;
                font-size: 13px;
                cursor: pointer;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                cursor: pointer;
                position: absolute;
                top: 7px;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #fff;
                border: 1px solid #c1c5cf;
                border-radius: 6px;
            }

            input[type=radio] ~ .checkmark {
                border-radius: 10px;
                width: 20px;
                height: 20px;
                top: 8px;
            }

            input[type=checkbox]:checked ~ .checkmark {
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.15);
            }

            input[type=radio]:checked ~ .checkmark {
                background-color: @secondary1;
                border-color: @secondary1;
            }

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            input:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                left: 8px;
                top: 5px;
                width: 6px;
                height: 10px;
                border: solid @secondary1;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            input[type=radio]:checked ~ .checkmark:after {
                border-color: #fff;
                left: 7px;
                top: 4px;
                width: 5px;
                height: 8px;
            }
        }

        .form-control.is-invalid {
            background-image: none;
        }
    }
}



@media(max-width: 1439px) {
    .borrow {
        main {
            .left-section {
                visibility: hidden;
                position: absolute;
            }

            .main-section {
                .body {
                    .main-box {
                        .badges {
                            flex-wrap: wrap;
                            justify-content: space-between;

                            img {
                                width: 80px;
                            }
                        } 
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .borrow {
        main {
            .main-section {
                width: 100%;
                .body {
                    min-width: 100%;
                    padding-top: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    padding: 30px 20px 55px;

                    .main-box {
                        order: 2;
                        max-width: 100%;
                        min-width: 100%;
                        margin: 0;
                        padding: 30px 15px;

                        .progress {
                            margin-bottom: 10px;
                            height: 10px;
                        }

                        .fine-print {
                            padding: 15px;
                        }

                        .badges {
                            flex-wrap: wrap;
                            justify-content: space-between;

                            img {
                                width: 80px;
                            }
                        }

                        .actions {
                            gap: 10px;

                            .continue,
                            .previous {
                                padding: 0;
                                font-weight: 600;
                                width: 100%;
                            }
                        }

                        .term-months {
                            .form-label {
                                height: 48px;
                                line-height: 48px;
                            }

                            .form-control {
                                width: 20%;
                                padding: 0 6px 0 0;
                            }
                        }

                        .loan-purpose {
                            .btn-group {
                                .btn {
                                    max-width: 50%;
                                    font-weight: 600;
                                    font-size: 15px;
                                    line-height: 20px;
                                    padding: 15px;
                                }
                            }
                        }

                        .property {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            h4 {
                                width: 50%;
                                margin-top: 30px !important;
                            }

                            p {
                                margin-top: 10px;
                            }

                            .unit-number,
                            .street-number,
                            .street-name,
                            .street-type,
                            .state,
                            .postcode,
                            .suburb,
                            .estimated-value,
                            .current-debt,
                            .zone-type {
                                width: 100%;
                            }

                            .add-property {
                                width: 100%;
                            }
                        }

                        .thanks {
                            text-align: left;
                        }

                        .quote-details {
                            border: 0px;
                            background-color: @secondary3;
                            padding: 12px !important;

                            tr:first-child {
                                th {
                                    border-top: 0px;
                                }

                                td {
                                    border-top: 0px;
                                }
                            }

                            tr {
                                display: flex;
                                flex-direction: column;
                            }

                            th {
                                background-color: inherit;
                                border: inherit;
                                width: 100%;
                                font-weight: 600;
                            }

                            td {
                                background-color: inherit;
                                background-color: #F2FAFA;
                                border: inherit;
                                font-weight: 400;
                            }

                            .less {
                                display: none;
                            }
                        }
                    }

                    .box {
                        position: inherit;
                        min-width: 100%;
                        margin: 0;

                        &.bright {
                            order: 1;
                            padding: 15px;
                            width: 100%;
                            height: 82px;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 18px;

                            .icon {
                                min-width: 50px;
                                line-height: 50px;
                                font-size: 30px;
                            }
                        }

                        &.help {
                            order: 3;
                        }
                    }
                }
            }
        }

        .footer-wrapper {
            padding: 44px !important;
        }
    }
}

@import '../variables.less';

.broker {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    color: @primary1;

    &> .spinner-border {
        margin: auto auto;
    }

    .btn {
        border-radius: 6px;
        height: 45px;
        font-size: 14px;
        padding: 10px;
        border-width: 2px;

        &.btn-primary,
        &.btn-primary a {
            background-color: @primary1;
            border-color: @primary1;
            color: #fff;
            width: 240px;

            &:not(:disabled):not(.disabled):hover {
                color: #fff;
                box-shadow: 0 6px 20px rgba(22, 37, 76, 0.6);
            }

            &:not(:disabled):not(.disabled):active {
                background: transparent;
                color: @primary1;
                border-color: @primary1;
            }

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):active:focus {
                box-shadow: none;
            }
        }
    }

    .form-control {
        border: 1px solid @primary3;
        border-radius: 6px;
        height: 48px;
        font-size: 15px;
        color: @primary1;

        &:not(:disabled):not(.disabled):focus {
            @input-glow();
            color: @primary1;
        }

        &.is-invalid {
            border-color: @invalid;
        }

        &.is-invalid:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .rdt.is-invalid .form-control {
        border-color: @invalid;

        &:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .invalid-feedback {
        color: @invalid;
    }

    .form-group {
        .form-label {
            color: @typography1;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            color: @primary2;
        }

        .form-check {
            display: block;
            position: relative;
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.form-check-inline {
                display: inline;
            }

            &.radio {
                padding-left: 26px;

                label {
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: uppercase;
                }
            }

            label {
                color: @primary1;
                font-size: 13px;
                cursor: pointer;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                cursor: pointer;
                position: absolute;
                top: 7px;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #fff;
                border: 1px solid #c1c5cf;
                border-radius: 6px;
            }

            input[type=radio] ~ .checkmark {
                border-radius: 10px;
                width: 20px;
                height: 20px;
                top: 8px;
            }

            input[type=checkbox]:checked ~ .checkmark {
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.15);
            }

            input[type=radio]:checked ~ .checkmark {
                background-color: @secondary1;
                border-color: @secondary1;
            }

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            input:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                left: 8px;
                top: 5px;
                width: 6px;
                height: 10px;
                border: solid @secondary1;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            input[type=radio]:checked ~ .checkmark:after {
                border-color: #fff;
                left: 7px;
                top: 4px;
                width: 5px;
                height: 8px;
            }
        }
    }

    .form-control.is-invalid {
        background-image: none;
    }

    a,
    a:hover,
    a:active,
    a:visited {
        color: @secondary1;
    }

    .top-bar {
        background-color: #fff;
        height: 64px;

        > .container-fluid {
            padding: 0;
        }

        .logo {
            height: 28px;
            margin-top: 3px;
        }

        .auth {
            position: absolute;
            right: 45px;
            font-size: 14px;
            color: @primary1;
            top: 10px;

            a {
                font-size: 14px;
                color: @primary1;
            }

            .dropdown-toggle {
                background-color: #daf0f0;
                border-radius: 100px;
                width: 45px;
                height: 45px;
                font-size: 24px;
                line-height: 42px;
                padding: 0;
                text-align: center;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                padding: 0;
                border: 0;
                box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
                border-radius: 6px;

                a {
                    color: @primary1;
                    text-decoration: none;
                    font-family: 'Poppins Medium';
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    padding: 0 20px;

                    &:hover {
                        background-color: #fff;
                        color: @primary1;
                    }

                    &.refer-friend,
                    &.help-centre {
                        background-color: @secondary3;
                    }

                    &.active {
                        background-color: #fff;
                    }
                }
            }
        }

        .left-nav-collapse {
            display: none;
        }
    }

    main {
        display: flex;
        flex-direction: row;
        flex: 1;

        .left-nav {
            width: 255px;
            min-width: 255px;
            font-size: 14px;

            .profile {
                margin: 0 15px 28px 15px;
                background-color: #f9f9f9;
                padding: 15px;
                border-radius: 6px;
                color: @primary1;

                .name {
                    font-size: 16px;
                    font-family: 'Poppins SemiBold';
                }

                .label,
                .value {
                    display: inline-block;
                    font-size: 13px;
                }

                .label {
                    width: 60%;
                    font-family: 'Poppins SemiBold';
                }

                .value {
                    width: 40%;
                    text-align: right;
                }

                .credit-number {
                    font-size: 12px;
                    border-top: 1px solid #eaeaea;
                    margin-top: 10px;
                    padding-top: 10px;

                    .label {
                        font-size: 12px;
                    }

                    .value {
                        font-size: 12px;
                    }
                }
            }

            .nav-item {
                display: block;
                color: #232222;
                padding: 12px 18px;
                margin: 8px 15px;
                font-size: 13px;

                &:hover {
                    text-decoration: none;
                    color: @secondary1;
                }

                svg {
                    margin-right: 18px;
                    width: 18px;
                    height: 18px;
                }

                &.active {
                    color: #fff;
                    background-color: @primary1;
                    border-radius: 6px;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

        .right-section {
            width: 100%;
            display: flex;
            background-color: #f9f9f9;
        }

        .body {
            padding: 55px 45px;
            width: 100%;

            h1 {
                font-size: 24px;
                font-family: 'Poppins Bold';
                color: @primary1;
            }

            &.applications {
                max-width: 1190px;
                margin: 0 auto;

                .submit-button {
                    float: right;
                    margin-top: -10px;
                    margin-bottom: 20px;
                }
            }

            .main-box {
                background-color: #fff;
                box-shadow: 0px 6px 30px rgba(22, 37, 76, 0.07);
                border-radius: 8px;
                padding: 30px 40px;
                margin-top: 40px;
                width: 100%;

                &:first-child {
                    margin-top: 0;
                }

                h2 {
                    font-family: 'Poppins Bold';
                    font-size: 17px;
                    color: @primary1;
                }

                &.broker-reward {
                    font-size: 15px;

                    p {
                        width: calc(100% - 163px - 113px - 100px);
                    }

                    .broker-reward-register {
                        float: right;
                        background-color: #d2eded;
                        color: @primary1;
                        border: none;
                        border-radius: 30px;
                        font-size: 13px;
                        padding: 12px 20px;
                        margin-left: 50px;
                        font-family: 'Poppins Bold';
                        position: relative;
                        top: -40px;
                        width: 173px;

                        &:hover {
                            color: @primary1;
                        }

                        &:active {
                            background-color: #d2eded;
                        }
                    }

                    svg {
                        float: right;
                        position: relative;
                        top: -15px;
                        margin-left: 50px;
                    }

                    @media only screen and (max-width: 991px) {
                        text-align: center;

                        p {
                            width: 100%;
                            margin-top: 20px;
                        }

                        svg {
                            display: block;
                            float: none;
                            margin: 10px auto;
                            top: 0;
                        }

                        h2 {
                            text-align: center;
                            margin-top: 24px;
                        }

                        .broker-reward-register {
                            display: block;
                            float: none;
                            margin: 30px auto 20px auto;
                            top: 0;
                        }
                    }
                }

                .spinner {
                    text-align: center;
                    font-size: 28px;
                }
            }

            .pagination {
                display: inline-block;
                width: fit-content;
                margin-top: 6px;

                .page-item {
                    display: inline-block;

                    .page-link {
                        border: 0;
                        width: 26px;
                        height: 26px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 26px;
                        padding: 0;
                        color: @primary2;
                        font-family: 'Poppins SemiBold';
                        margin-left: 4px;
                        border-radius: 6px;
                    }

                    &:first-child {
                        .page-link {
                            margin-left: 0;
                        }
                    }

                    &.active {
                        .page-link {
                            background-color: @primary1;
                            color: #fff;
                        }
                    }
                }
            }

            .per-page {
                width: 80px;
                height: 32px;
                display: inline-block;
                margin-left: 30px;
                font-size: 14px;
                font-family: 'Poppins SemiBold';
                color: @primary2;
                padding: 0 12px;
                line-height: 32px;

                &:first-child {
                    margin-left: 0;
                }
            }

            .list-table {
                font-size: 12px;
                margin-top: 2px;
                width: calc(100% + 80px);
                position: relative;
                left: -40px;
                border-left: 0;
                border-right: 0;
                color: @primary1;

                @media only screen and (max-width: 991px) {
                    left: -15px;
                    width: calc(100% + 30px);
                    border-top: 0;
                    border-bottom: 0;
                }

                th {
                    background-color: #fafafa;
                    color: @primary2;
                    font-weight: 400;
                    border-bottom-width: 1px;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-size: 11px;

                    @media only screen and (max-width: 991px) {
                        display: none;
                    }

                    svg {
                        margin-left: 6px;
                    }
                }

                th,
                td {
                    border-left: 0;
                    border-right: 0;
                    padding: 16px;

                    @media only screen and (max-width: 991px) {
                        border-top: 0;
                        border-bottom: 0;
                    }

                    &:last-child {
                        padding-right: 40px;
                    }

                    &:first-child {
                        padding-left: 40px;
                    }

                    @media only screen and (max-width: 991px) {
                        margin-top: 15px;
                        margin-left: 15px;
                        height: 18px;

                        &:last-child {
                            padding-right: 0;
                        }

                        &:first-child {
                            width: 100%;
                            padding: 6px 15px;
                            background-color: #fafafa;
                            text-transform: uppercase;
                            height: auto;
                            margin-left: 0;
                            font-weight: bold;
                        }
                    }

                }

                @media only screen and (max-width: 991px) {
                    tr {
                        display: block;
                    }

                    td {
                        display: block;
                        padding: 0;
                    }
                }
            }
        }
    }

    .footer-wrapper {
        background-color: #f9f9f9;;
        padding: 50px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        margin-top: auto;
        text-align: justify;
        border-left: 255px solid #fff;

        strong,
        a {
            font-family: 'Poppins SemiBold';
        }

        p {
            margin: 0;
        }
    }

    .toasts {
        width: 350px;
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 10;

        .close {
            display: none;
        }
    }

    @media only screen and (max-width: 991px) {
        .top-bar {
            > .container-fluid {
                padding: 0;
            }

            .logo {
                margin-left: 0;
                margin-top: 0;
            }

            .auth {
                right: 70px;
            }

            .left-nav-collapse {
                display: block;
                float: right;
                border-color: transparent;
                box-shadow: none;
                background-color: transparent;
                color: @primary1;
                padding: 0;
                width: 40px;
                height: 40px;
                position: relative;
                top: -4px;
                right: -2px;

                &:not(:disabled):not(.disabled):hover {
                    box-shadow: none;
                    background-color: transparent;
                    color: @primary1;
                }

                &:not(:disabled):not(.disabled):active {
                    border-color: transparent;
                }

                svg {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        main {
            display: block;

            .left-nav {
                position: static;
                margin: 0;
                width: 100%;
                min-height: auto;
                height: 0px;
                transition: all 0.5s;
                overflow: hidden;

                &.open {
                    height: 646px;
                }

                .nav-item {
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        border-bottom: 1px solid #e8e8e8;
                    }
                }
            }

            .body {
                padding: 30px 20px 30px 20px;
                margin: 0;
                min-height: auto;

                .main-box {
                    padding: 20px 15px;
                    margin-top: 15px;

                    &:first-of-type {
                        margin-top: 30px;
                    }
                }

                h2 {
                    font-size: 18px;
                }
            }
        }

        .footer-wrapper {
            padding: 22px 22px 154px 22px;
            margin-left: 0;
            border-left: 0;

            .footer {
                padding: 0;

                p {
                    margin: 0;
                }
            }
        }
    }
}

@import '../variables.less';

#root >.auth {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &> .spinner-border {
        margin: auto auto;
    }

    .btn {
        border-radius: 54px;
        height: 45px;
        font-size: 14px;
        padding: 10px;
        border-width: 2px;

        &.btn-primary,
        &.btn-primary a {
            background-color: @primary1;
            border-color: @primary1;
            color: #fff;
            width: 240px;

            &:not(:disabled):not(.disabled):hover {
                color: #fff;
                box-shadow: 0 6px 20px rgba(22, 37, 76, 0.6);
            }

            &:not(:disabled):not(.disabled):active {
                background: transparent;
                color: @primary1;
                border-color: @primary1;
            }

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):active:focus {
                box-shadow: none;
            }
        }
    }

    .form-control {
        border: 1px solid @primary3;
        border-radius: 6px;
        height: 48px;
        font-size: 15px;
        color: @primary1;

        &:not(:disabled):not(.disabled):focus {
            @input-glow();
            color: @primary1;
        }

        &.is-invalid {
            border-color: @invalid;
        }

        &.is-invalid:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .input-group>.custom-select:not(:first-child),
    .input-group>.form-control:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rdt.is-invalid .form-control {
        border-color: @invalid;

        &:not(:disabled):not(.disabled):focus {
            @input-glow-invalid();
        }
    }

    .invalid-feedback {
        color: @invalid;
    }

    .form-group {
        .form-label {
            color: @typography1;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            color: @primary2;
        }

        .form-check {
            display: block;
            position: relative;
            padding-left: 32px;
            margin-bottom: 12px;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.form-check-inline {
                display: inline;
            }

            &.radio {
                padding-left: 26px;

                label {
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: uppercase;
                }
            }

            label {
                color: @primary1;
                font-size: 13px;
                cursor: pointer;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                cursor: pointer;
                position: absolute;
                top: 7px;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #fff;
                border: 1px solid #c1c5cf;
                border-radius: 6px;
            }

            input[type=radio] ~ .checkmark {
                border-radius: 10px;
                width: 20px;
                height: 20px;
                top: 8px;
            }

            input[type=checkbox]:checked ~ .checkmark {
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.15);
            }

            input[type=radio]:checked ~ .checkmark {
                background-color: @secondary1;
                border-color: @secondary1;
            }

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            input:checked ~ .checkmark:after {
                display: block;
            }

            .checkmark:after {
                left: 8px;
                top: 5px;
                width: 6px;
                height: 10px;
                border: solid @secondary1;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            input[type=radio]:checked ~ .checkmark:after {
                border-color: #fff;
                left: 7px;
                top: 4px;
                width: 5px;
                height: 8px;
            }
        }

        .form-control.is-invalid {
            background-image: none;
        }
    }

    a,
    a:hover,
    a:active,
    a:visited {
        color: @secondary1;
    }

    .top-bar {
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        background-color: #fff;
        height: 64px;

        .logo {
            height: 36px;
            margin-top: 3px;
            margin-left: 76px;
        }

        .auth {
            position: absolute;
            right: 76px;
            font-size: 14px;
            color: @primary1;

            a {
                font-size: 14px;
                color: @primary1;
            }

            .dropdown-menu {
                background-color: @primary1;
                a {
                    color: #fff;

                    &:hover {
                        background-color: #fff;
                        color: @primary1;
                    }
                }
            }
        }
    }

    main {
        h1.title {
            font-size: 36px;
            line-height: 50px;
            text-align: center;
            font-family: 'Poppins SemiBold';
            color: @primary1;
            padding: calc(1rem - 13px) 10px 17px;
            border-bottom: 1px solid #e8e8e8;
        }

        h2 {
            font-family: 'Poppins Regular';
            font-size: 26px;
            color: @primary1;
            text-align: center;
        }

        .body {
            margin-top: 45px;
            min-height: 600px;
            padding: 30px;

            &.login {
                max-width: 400px;
                margin: 45px auto 0 auto;

                .input-group-prepend {
                    padding: 15px;
                    border: 1px solid #c1c5cf;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                button {
                    width: 100%;
                }

                .forgot-password {
                    font-size: 12px;
                    text-align: right;
                    float: right;
                    margin-bottom: 16px;
                }

                .sign-up {
                    margin-top: 16px;
                    font-size: 14px;
                }
            }

            &.forgot-password {
                max-width: 400px;
                margin: 45px auto 0 auto;
                text-align: center;

                .input-group-prepend {
                    padding: 15px;
                    border: 1px solid #c1c5cf;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                button {
                    width: 100%;
                }

                .login {
                    display: inline-block;
                    margin-top: 16px;
                    font-size: 14px;
                }
            }

            &.login-link,
            &.verify-email {
                text-align: center;

                .alert {
                    max-width: 800px;
                    margin: 0 auto;
                }

                .login {
                    display: inline-block;
                    margin-top: 16px;
                    font-size: 14px;
                }
            }

            &.forgot-password-sent,
            &.verify-email-verified {
                text-align: center;

                .alert {
                    max-width: 800px;
                    margin: 0 auto;
                }

                .login {
                    display: inline-block;
                    margin-top: 16px;
                    font-size: 14px;
                }
            }
        }
    }

    .footer-wrapper {
        background-color: @primary1;
        color: #fff;
        padding: 77px 60px 93px 60px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 300;
        margin-top: auto;

        strong,
        a {
            font-weight: 400;
        }
    }

    .toasts {
        width: 350px;
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 10;

        .close {
            display: none;
        }
    }

    @media only screen and (max-width: 991px) {
        .top-bar {
            .logo {
                margin-left: 0;
            }

            .auth {
                right: 0;
            }
        }

        main {
            .body {
                padding: 15px 15px 30px 15px;
                margin: 0;
                min-height: auto;

                h2 {
                    font-size: 18px;
                }
            }
        }

        .footer-wrapper {
            padding: 51px 44px;
        }
    }
}

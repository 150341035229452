@import '~/variables.less';

.investor-marketplace-withdraw-modal {
    .modal-content {
        .modal-body {
            padding-top: 20px;

            .form-group {
                &.amount {
                    margin-top: calc(30px - 1rem);
                    margin-bottom: 30px;
                }
            }

            p:last-child {
                margin-bottom: 0;
            }

            .btn-primary {
                margin-top: calc(30px - 1rem);
                width: 180px;
            }

            table.bank-details {
                border: none !important;
                table-layout: fixed;

                th {
                    padding: 0 !important;
                    border: none !important;
                    width: 30%;
                }

                td {
                    padding: 0 !important;
                    border: none !important;
                }
            }
        }
    }
}

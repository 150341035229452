@import '~/variables.less';

.investor main .body.marketplace-top-up-withdraw {
    font-size: 14px;
    max-width: 760px;
    margin: 0 auto;

    .content-box.top-up,
    .content-box.withdraw {
        width: 320px;
        display: inline-block;
        text-align: center;
        padding-bottom: 40px;
        height: 347px;

        .title {
            margin-top: 24px;
            font-size: 17px;
            font-family: 'Poppins SemiBold';
        }

        p {
            font-size: 13px;
            margin-top: 20px;
        }

        button {
            background-color: @primary1;
            color: #fff;
            border: none;
            box-shadow: none;
            font-size: 14px;
            width: 180px;
            height: 45px;
            margin-top: 25px;
            padding: 0;
        }

        @media only screen and (max-width: 991px) {
            width: 100%;
            display: block;
            height: fit-content;
        }
    }

    .content-box.withdraw {
        margin-left: 30px;
        position: relative;
        top: -2px;

        svg {
            margin-top: 8px;
        }

        @media only screen and (max-width: 991px) {
            margin-left: 0;
            position: static;
            margin-top: 20px;
        }
    }

    p:first-of-type {
        margin-bottom: 0;
    }

    .form-check {
        display: inline;
        margin-left: 20px;

        &:first-of-type {
            margin-left: 0;
        }
    }

    fieldset {
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 25px;
        margin: 20px 0;
    }

    p.instructions {
        text-align: center;
    }

    .submit {
        margin-left: auto;
        display: block;
        padding: 10px 40px;
        width: auto;
    }
}

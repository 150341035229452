@import '../public.less';

.invest {
    .steps {
        margin: 80px auto 0 auto;
        width: 795px;

        .line,
        .active-line {
            width: 636px;
            height: 4px;
            background-color: @primary3;
            position: relative;
            left: 67px;
            top: 19px;
            z-index: -1000;
        }

        .active-line {
            z-index: -999;
            background-color: @secondary1;
            top: 15px;
            width: 0;
        }

        &.active-2 .active-line {
            width: 159px;
        }

        &.active-3 .active-line {
            width: 318px;
        }

        &.active-4 .active-line {
            width: 477px;
        }

        &.active-5 .active-line {
            width: 636px;
        }

        .step {
            width: 159px;
            display: inline-block;
            text-align: center;
            color: @primary3;
            font-size: 11px;
            line-height: 13px;

            .number {
                width: 26px;
                height: 26px;
                background-color: @primary3;
                border-radius: 13px;
                border: 2px solid #fff;
                margin: 0 auto;
                font-weight: bold;
                padding-top: 5px;
                color: #fff;
            }

            .label {
                margin-top: 6px;
            }
        }

        &.active-1 .step-1,
        &.active-2 .step-1,
        &.active-2 .step-2,
        &.active-3 .step-1,
        &.active-3 .step-2,
        &.active-3 .step-3,
        &.active-4 .step-1,
        &.active-4 .step-2,
        &.active-4 .step-3,
        &.active-4 .step-4,
        &.active-5 .step-1,
        &.active-5 .step-2,
        &.active-5 .step-3,
        &.active-5 .step-4,
        &.active-5 .step-5 {
            .number {
                background-color: @secondary1;
            }

            .label {
                color: @primary2;
            }
        }
    }

    .steps-5 {
        width: 795px;

        .line {
            width: 636px;
        }

        &.active-2 .active-line {
            width: 159px;
        }

        &.active-3 .active-line {
            width: 318px;
        }

        &.active-4 .active-line {
            width: 477px;
        }

        &.active-5 .active-line {
            width: 636px;
        }

        .step {
            width: 159px;
        }
    }

    .register,
    .register-details,
    .register-bank-account,
    .register-product-disclosure,
    .register-qualifier,
    .register-retail-investor,
    .register-distribution-preference,
    .register-information-memorandum,
    .register-marketplace-qualifier {
        max-width: 700px;
        background-color: #fff;
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        border-radius: 6px;
        padding: 70px 100px 60px 100px;
        font-weight: 400;
        margin-top: 76px;

        h3 + p:not(.intro) {
            margin-top: 24px;
        }

        h4 {
            margin-top: 16px;
        }

        .try,
        .skip {
            @secondary-button();
            float: right;
            position: relative;
            top: -4px;

            .hand {
                position: relative;
                top: -2px;
            }

            &:hover {
                .hand {
                    top: -4px;
                    transition: top 0.4s;
                }
            }
        }

        .intro {
            color: @primary1;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 24px;
        }

        .continue,
        .previous {
            margin-top: 24px;

            .spinner-grow {
                margin-right: 10px;
                position: relative;
                top: -2px;
            }

            .spinner-border {
                border-width: 2px;
            }
        }

        .previous {
            float: left;
        }

        .previous ~ .continue {
            margin-left: 20px;
        }
    }

    .register {
        .first-name {
            clear: both;
            float: left;
            width: 240px;
        }

        .last-name {
            display: inline-block;
            width: 240px;
            margin-left: 20px;
        }
    }

    .register-details {
        .available-funds,
        .first-name,
        .date-of-birth,
        .suburb,
        .state,
        .id-proof-number,
        .company-tax-file-number,
        .trust-tax-file-number {
            clear: both;
            float: left;
            width: 240px;
        }

        .investor-type,
        .last-name,
        .tax-file-number,
        .postcode,
        .country,
        .driver-licence-card-number,
        .driver-licence-state,
        .id-proof-expiry-date,
        .australian-company-number,
        .australian-business-number {
            display: inline-block;
            width: 240px;
            margin-left: 20px;
        }

        .driver-licence-card-number + .id-proof-expiry-date {
            clear: both;
            float: left;
            margin-left: 0;
        }

        .country,
        .driver-licence-state {
            float: left;
        }

        .continue {
            display: block;
            clear: both;
        }

        h4.trust,
        h4.company,
        h4.individual-1,
        h4.individual-2,
        .id-proof-type,
        .street-address {
            clear: both;
        }
    }

    .register-marketplace-qualifier {
        .form-group {
            margin: 0 0 36px;

            .group-description,
            .group-question {
                font-size: 13px;
            }

            .form-check.radio {
                display: block;
                line-height: 29px;
                margin: 0;

                label {
                    border-radius: 2px;
                    border: 1px solid transparent;
                    margin: 0;
                    padding: 3px 7px;
                    transition: background-color 0.15s ease, border-color 0.15s ease;
                }
            }
        }

        p {
            a {
                font-weight: bold;
            }
        }

        &.unsuitable {
            .previous {
                float: none;
            }
        }
    }

    .form-cards {
        display: flex;
        margin: 32px -8px 24px;

        .form-card {
            background-color: #fafafa;
            border-radius: 15px;
            border: 2px solid #f3f3f4;
            color: @primary1;
            display: flex;
            flex-direction: column;
            font-size: 15px;
            margin: 8px;
            padding: 16px 24px;
            width: 50%;

            .form-check {
                label {
                    font-family: 'Poppins SemiBold', sans-serif;
                }

                .checkmark {
                    top: 9px;
                }
            }

            p {
                color: #212529;
                font-size: 12px;
                line-height: 16px;
                margin: 0 0 8px;
            }

            .card-description {
                color: #212529;
                font-size: 12px;
                line-height: 1.3em;
                margin: 0 0 8px;

                ul {
                    margin: 0 0 8px;
                    padding: 0 0 0 16px;
                }

                li {
                    padding: 0 0 0.25em;
                    position: relative;

                    &:last-child {
                        padding: 0;
                    }
                }
            }
        }

        .form-card-selected {
            border-color: @secondary1;
            transition: border-color 0.3s ease;
        }
    }

    .form-cards-vertical {
        flex-direction: column;

        .form-card {
            width: 100%;
        }
    }

    .faq {
        margin-top: 70px;
        max-width: 1053px;
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;

        .card-deck {
            display: flex;
            justify-content: center;
            margin-bottom: -15px;

            .card {
                padding: 24px;
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
                border-radius: 5px;
                border: none;
                color: @primary2;
                font-size: 15px;
                margin-bottom: 15px;
                max-width: 326px;
                width: 100%;

                .header {
                    color: @primary1;
                    font-weight: bold;
                }
            }
        }
    }

    .investor-account-type {
        padding: 55px 45px;

        .type-boxes {
            align-items: center;
            display: flex;
            flex-flow: wrap;
            justify-content: center;

            .type-box {
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
                display: flex;
                flex-direction: column;
                font-size: 13px;
                margin: 40px 15px 0;
                max-width: 400px;
                padding: 30px 40px;
                text-align: center;
                width: 100%;

                h3 {
                    margin: 0 0 28px;
                }

                .box-description {
                    text-align: left;
                }

                .box-disclaimer {
                    font-size: 12px;
                    font-style: italic;
                    margin: 0 0 36px;
                    padding: 0 0 0 0.75em;
                    position: relative;

                    &:before {
                        content: '*';
                        display: block;
                        left: 0;
                        position: absolute;
                        top: 0.15em;
                    }
                }

                .box-footer {
                    margin: auto 0 0;
                }

                .box-button {
                    margin: 0 auto 20px;
                    max-width: 240px;
                    width: 100%;
                }

                .box-link {
                    display: block;
                    font-family: 'Poppins SemiBold', sans-serif;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .invest {
        padding: 0 20px;

        .steps {
            margin-top: 22px;
            width: 360px;

            .line {
                width: 288px;
                left: 45px;
            }

            .active-line {
                left: 36px;
            }

            &.active-2 .active-line {
                width: 72px;
            }

            &.active-3 .active-line {
                width: 144px;
            }

            &.active-4 .active-line {
                width: 216px;
            }

            &.active-5 .active-line {
                width: 288px;
            }

            .step {
                width: 72px;

                .label {
                    display: none;
                }
            }
        }

        .steps-5 {
            width: 360px;

            .line {
                width: 288px;
            }

            .active-line {
                left: 36px;
            }

            &.active-2 .active-line {
                width: 72px;
            }

            &.active-3 .active-line {
                width: 144px;
            }

            &.active-4 .active-line {
                width: 216px;
            }

            &.active-5 .active-line {
                width: 288px;
            }

            .step {
                width: 72px;
            }
        }

        .register,
        .register-details,
        .register-bank-account,
        .register-product-disclosure,
        .register-qualifier,
        .register-retail-investor,
        .register-distribution-preference,
        .register-information-memorandum,
        .register-marketplace-qualifier {
            margin-top: 22px;
            padding: 40px 28px 50px 28px;

            .try {
                margin-bottom: -35px;
                top: 56px;
            }

            .available-funds,
            .investor-type,
            .first-name,
            .last-name,
            .date-of-birth,
            .tax-file-number,
            .suburb,
            .postcode,
            .state,
            .country,
            .id-proof-number,
            .id-proof-expiry-date,
            .driver-licence-card-number,
            .company-tax-file-number,
            .australian-company-number,
            .trust-tax-file-number,
            .australian-business-number {
                display: block;
                width: auto;
                margin-left: 0;
                float: none;
            }

            .continue {
                display: block;
                margin: 26px auto 0 auto;
            }

            .previous {
                display: none;
            }
        }

        .register-details {
            .intro {
                width: 50%;
            }
        }

        .faq {
            margin-top: 40px;

            .card-deck {
                align-items: center;
                flex-direction: column;
            }
        }

        &.income-trust {
            .register-details {
                .intro {
                    width: 100%;
                }
            }
        }

        .investor-account-type {
            margin: 22px auto 0;
            padding: 0;

            .type-boxes {
                flex-direction: column;

                .type-box {
                    margin: 15px 0 0;
                    padding: 20px 15px;

                    &:first-child {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@import '~/variables.less';

.investor main .body.income-trust-invest {
    font-size: 14px;
    max-width: 890px;
    margin: 0 auto;

    .content-boxes {
        display: flex;
        justify-content: space-between;

        .content-box.invest {
            width: 380px;
            display: inline-block;
            text-align: center;
            padding-bottom: 40px;

            .title {
                margin-top: 24px;
                font-size: 22px;
                font-family: 'Poppins SemiBold';
            }

            .target-return-label {
                text-align: center;
                font-family: 'Poppins SemiBold';
                margin-top: 20px;
            }

            .target-return-rate {
                text-align: center;
                font-family: 'Poppins Bold';
                color: @secondary1;
                font-size: 38px;
            }

            button {
                background-color: @primary1;
                color: #fff;
                border: none;
                box-shadow: none;
                font-size: 14px;
                width: 180px;
                height: 45px;
                margin-top: 25px;
                padding: 0;
            }

            &:last-child {
                margin-left: 40px;
            }

            @media only screen and (max-width: 1190px) {
                width: 100%;
                display: block;
                height: fit-content;

                &:last-child {
                    margin-left: 0px;
                }
            }
        }

        @media only screen and (max-width: 1190px) {
            flex-direction: column;
        }
    }

    .content-box.pending-approval {
        padding: 70px 100px 60px 100px;

        h3 {
            font-family: 'Poppins Bold', sans-serif;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            color: @primary1;
            margin-bottom: 24px;
        }
    }

    .form-check {
        display: inline;
        margin-left: 20px;

        &:first-of-type {
            margin-left: 0;
        }
    }

    fieldset {
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 25px;
        margin: 20px 0;
    }

    p.instructions {
        text-align: center;
    }

    .submit {
        margin-left: auto;
        display: block;
        padding: 10px 40px;
        width: auto;
    }
}

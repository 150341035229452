@import '~/variables.less';

.investor main .body.faq {
    max-width: 730px;
    margin: 0 auto;

    .accordion {
        font-size: 14px;

        .card {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #e8e8e8;

            &:last-child {
                border-bottom: 0;
            }

            .card-header {
                background-color: transparent;
                color: @secondary1;
                cursor: pointer;
            }
        }
    }
}

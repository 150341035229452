@import '../variables.less';

.broker main .body.application-request {
    max-width: 1190px;
    margin: 0 auto;

    h2 {
        margin-bottom: 20px;
    }

    .application-form {
        h3 {
            font-size: 17px;
            font-family: 'Poppins SemiBold';
        }

        p {
            font-size: 15px;
        }

        .contact-details,
        .loan-details {
            display: flex;

            .email,
            .loan-amount,
            .phone,
            .term-months {
                flex-direction: row;
                flex: 1 1 50%;
            }

            .email,
            .term-months {
                margin-right: 20px;
            }
        }

        .contact-information {
            .comments textarea {
                height: auto;
            }
        }

        .property-information {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            column-gap: 20px;
            width: 100%;
            margin-bottom: 30px;

            h2 {
                flex-direction: column;
                flex: 1 1 100%;
            }

            .unit-number,
            .street-number,
            .street-name,
            .street-type {
                flex: 1 1 45%;
            }

            .suburb,
            .state,
            .postcode {
                flex: 1 1 30%;
            }

            .estimated-value,
            .current-debt,
            .zone-type {
                flex: 1 1 30%;
            }

            .add-property,
            .remove-property {
                width: 240px;
            }

            .remove-property {
                background-color: @primary1;
                border-color: @primary1;
                color: #fff;
                margin-right: 10px;
            }
        }

        .supporting-documents {
            margin-bottom: 30px;

            .file-upload-wrapper {
                width: 240px;
                background-color: #d2eded;
                color: @primary1;
                border-radius: 50px;
                height: 45px;
                position: relative;
                cursor: pointer;
                font-size: 12px;
                line-height: 16px;
                padding: 15px;
                text-align: center;

                .label {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                svg {
                    font-size: 15px;
                    position: relative;
                    top: -1px;
                }

                .form-control-file {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                }

                &:not(:disabled):not(.disabled):hover {
                    box-shadow: 0 6px 20px rgba(22, 37, 76, 0.6);
                }
            }

            .file-upload-list {
                margin: 5px 0 10px 0;

                ul {
                    padding-inline-start: 5px;
                }

                .upload-list-item {
                    list-style-type: none;
                }
            }
        }

        .submit-application {
            width: 240px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .broker main .body.application-request {
        .application-form {
            .loan-details {
                display: block;

                .email,
                .term-months {
                    margin-right: 0;
                }
            }

            .property-information {
                display: block;

                .add-property,
                .remove-property {
                    width: 100%;
                }

                .remove-property {
                    margin-bottom: 15px;
                }
            }

            .supporting-documents {
                .file-upload-wrapper {
                    width: 100%;
                }
            }

            .submit-application {
                width: 100%;
            }
        }
    }
}

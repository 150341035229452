@import '../variables.less';

.broker main .body.get-details {
    max-width: 710px;
    margin: 0 auto;

    input {
        font-size: 13px;
        outline: none;
    }

    h3 {
        font-size: 17px;
        font-family: 'Poppins Bold';
    }

    .btn-link {
        color: @secondary1;
        padding: 0;
        width: auto;
        margin: 0;
        height: auto;
        border: none;
        font-size: 11px;
    }

    .basic-information {
        .email,
        .password {
            .change {
                margin-left: 10px;
            }
        }
    }

    .broker-information {
        .postal-address {
            .change {
                margin-left: 10px;
            }
        }
    }

    .form-group {
        display: inline-block;
        width: 50%;
    }

    @media only screen and (max-width: 991px) {
        .form-group {
            display: block;
            width: 100%;
        }
    }
}

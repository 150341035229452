@import '~/variables.less';

.investor-marketplace-top-up-modal {
    .modal-content {
        .modal-body {
            padding-top: 20px;

            hr.spacing {
                margin: 1.8rem 0 1.3rem 0;
            }

            p.title {
                font-weight: bold;
            }

            .form-group {
                &.amount {
                    margin-top: calc(30px - 1rem);
                    margin-bottom: 30px;
                }
            }

            .instructions-2 {
                font-size: 13px;
            }

            p:last-child {
                margin-bottom: 0;
            }

            .btn-primary {
                margin-top: calc(30px - 1rem);
                width: 180px;
            }

            .tab-pane {
                padding: 24px 0;
            }

            .bpay-box {
                border: 1px solid #061c42;
                clear: both;
                display: inline-block;
                font-family: Helvetica, Arial, sans-serif;
                padding: 5px;

                .logo {
                    display: inline-block;
                    vertical-align: top;

                    svg {
                        width: 49px;
                        height: 72px
                    }
                }

                .details {
                    color: #061c42;
                    border: 1px solid #061c42;
                    display: inline-block;
                    font-size: 16px;
                    height: 66px;
                    margin: 3px;
                    padding: 10px;
                    width: 220px;
                }

                .notes {
                    color: #061c42;
                    font-size: 11px;
                    padding: 3px;

                    a {
                        color: #061c42;
                    }

                    strong {
                        font-size: 14px;
                    }
                }
            }

            .intro {
                margin-bottom: 20px;
            }

            .label,
            .value {
                display: inline-block;
                vertical-align: top;
            }

            .label {
                font-weight: bold;
                width: 38%;
            }

            .value {
                width: 62%;
            }
        }
    }

    &.complete {
        .modal-content {
            .modal-body {
                p {
                    text-align: left;
                }
            }
        }
    }
}

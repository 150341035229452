@import '../Public/public.less';

.partner-referral {
    min-height: 500px;

    .referral {
        max-width: 700px;
        background-color: #fff;
        box-shadow: 0 6px 30px rgba(22, 37, 76, 0.07);
        border-radius: 6px;
        padding: 70px 100px 60px 100px;
        font-weight: 400;
        margin-top: 76px;

        h4 {
            margin-top: 16px;
        }

        .intro {
            color: @primary1;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 24px;
        }

        .referral-partner-first-name,
        .first-name {
            clear: both;
            float: left;
            width: 240px;
        }

        .referral-partner-last-name,
        .last-name {
            float: left;
            width: 240px;
            margin-left: 20px;
        }

        .submit {
            margin-top: 24px;

            .spinner-grow {
                margin-right: 10px;
                position: relative;
                top: -2px;
            }

            .spinner-border {
                border-width: 2px;
            }
        }

        .comments textarea {
            height: auto;
        }
    }
}

@media only screen and (max-width: 768px) {
    .partner-referral {
        padding: 0 20px;

        .referral {
            margin-top: 12px;
            padding: 40px 28px 50px 28px;

            .first-name,
            .last-name,
            .phone,
            .email,
            .submit {
                display: block;
                margin: 26px auto 0 auto;
            }
        }
    }
}

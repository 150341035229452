@import '~/variables.less';

.investor main .body.investment-details {
    max-width: 996px;
    margin: 0 auto;

    .content-box {
        max-width: 684px;
    }

    .photo {
        overflow: hidden;
        height: 360px;
        border-radius: 8px;

        @media only screen and (max-width: 991px) {
            height: 196px;
        }

        img {
            margin-top: -87px;
            width: 100%;

            @media only screen and (max-width: 991px) {
                margin-top: -48px;
            }
        }
    }

    .invest-box {
        float: right;
        width: 200px;
        margin-top: 32px;
        padding: 18px;

        h3 {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
        }

        .amount {
            margin-top: 16px;
            font-size: 11px;

            .form-control {
                height: 45px;
                font-size: 13px;
            }

            .invalid-feedback {
                font-size: 11px;
            }
        }

        .btn {
            width: 100%;
            padding: 5px 0;
            height: auto;
        }

        p {
            margin-bottom: 0;
            font-size: 13px;
        }

        @media only screen and (max-width: 991px) {
            display: block;
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            box-shadow: 0px -6px 30px rgba(22, 37, 76, 0.07);

            h3 {
                display: none;
            }

            .amount {
                margin-top: 0;
                margin-bottom: 0;
            }

            .invest {
                margin-top: 16px;
                margin-bottom: 0;
            }
        }
    }

    .investment-progress {
        float: right;
        margin-top: 20px;
        font-size: 11px;
        text-align: right;

        .progress-graph {
            width: 124px;
            margin-left: auto;

            .progress {
                height: 9px;
                border-radius: 30px;
                background-color: #d2eded;

                .progress-bar {
                    border-radius: 30px;
                    background-color: @secondary1;
                }
            }
        }

        .funded {
            font-size: 13px;
            margin-top: 6px;
        }

        .remaining {
            font-size: 13px;
            margin-top: 6px;
        }
    }

    .name {
        font-size: 16px;
        margin-top: 20px;
        font-family: 'Poppins SemiBold';
    }

    .code {
        font-size: 13px;
    }

    h3 {
        font-size: 14px;
        font-family: 'Poppins SemiBold';
        padding-top: 30px;
        border-top: 1px solid #ededed;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .target-return,
    .lvr,
    .term,
    .term-remaining,
    .available {
        background-color: #f3f3f3;
        padding: 20px 10px;
        width: calc((100% - (15px * 3)) / 4);
        display: inline-block;
        margin-left: 15px;
        border-radius: 6px;
        text-align: center;
        font-family: 'Poppins SemiBold';
        margin-top: 15px;

        @media only screen and (max-width: 991px) {
            width: calc((100% - 15px) / 2);
        }

        .label {
            font-size: 12px;
        }

        .value {
            font-size: 14px;
            margin-top: 4px;
        }
    }

    .target-return {
        margin-left: 0;
    }

    .term,
    .term-remaining {
        @media only screen and (max-width: 991px) {
            margin-left: 0;
        }

        .number {
            display: inline;
        }

        .suffix {
            display: inline;
            font-size: 10px;
            font-weight: normal;
            margin: 0 8px 0 4px;
            text-transform: lowercase;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .description {
        font-size: 13px;
        line-height: 18px;

        strong {
            display: block;
            font-size: 14px;
            margin-bottom: 8px;
            font-family: 'Poppins SemiBold';
            padding-top: 30px;
            border-top: 1px solid #ededed;
            margin-top: 30px;
        }

        .colon {
            display: none;
        }
    }

    .total-loan-amount,
    .start-date,
    .scheduled-end-date,
    .purpose-of-funds,
    .property-value,
    .property-type,
    .property-security {
        background-color: #f3f3f3;
        padding: 20px 10px;
        width: calc((100% - (15px * 2)) / 3);
        display: inline-block;
        margin-left: 15px;
        border-radius: 6px;
        text-align: center;
        font-family: 'Poppins SemiBold';
        margin-top: 15px;

        .label {
            font-size: 12px;
        }

        .value {
            font-size: 14px;
            margin-top: 4px;
        }
    }

    .total-loan-amount,
    .property-value {
        margin-left: 0;
    }

    @media only screen and (max-width: 991px) {
        .total-loan-amount,
        .start-date,
        .property-value,
        .property-type {
            width: calc((100% - 15px) / 2);
        }

        .scheduled-end-date,
        .purpose-of-funds,
        .property-security {
            margin-left: 0;
            width: 100%;
        }
    }
}
